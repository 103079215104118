import { PageHeader } from '@components/PageHeader';
import { ProductTable } from './tables';

export const ProductManagementPage = () => {
  return (
    <div>
      <PageHeader title='Product Management' />
      <ProductTable />
    </div>
  );
};
