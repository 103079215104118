import { Stepper, StepperControl } from '@lib/Stepper';
import { useRegister } from '@network/hooks';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AccountInformationStep,
  AdditionalInformationStep,
  PersonalInformationStep,
  PricingPackageStep,
  StoreInformationStep,
} from './components';

export const RegisterPage = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const {
    disabledButton,
    errors,
    handleChange,
    handleSubmit,
    isLoading,
    values,
    setFieldValue,
    cityOptions,
    countryOptions,
    stateOptions,
    businessOptions,
    packageOptions,
    selectedPricing,
  } = useRegister();

  const steps = [
    'Account Information',
    'Personal Information',
    'Store Information',
    'Additional Information',
    'Pricing Package',
  ];

  const displayStep = (step: number) => {
    switch (step) {
      case 1:
        return (
          <AccountInformationStep
            errors={errors}
            handleChange={handleChange}
            values={values}
          />
        );

      case 2:
        return (
          <PersonalInformationStep
            errors={errors}
            handleChange={handleChange}
            values={values}
            cityOptions={cityOptions}
            stateOptions={stateOptions}
            countryOptions={countryOptions}
            setFieldValue={setFieldValue}
          />
        );

      case 3:
        return (
          <StoreInformationStep
            errors={errors}
            handleChange={handleChange}
            values={values}
            businessOptions={businessOptions}
          />
        );

      case 4:
        return (
          <AdditionalInformationStep
            errors={errors}
            handleChange={handleChange}
            values={values}
            setFieldValue={setFieldValue}
          />
        );

      case 5:
        return (
          <PricingPackageStep
            errors={errors}
            handleChange={handleChange}
            values={values}
            pricingOptions={packageOptions}
            selectedPricing={selectedPricing}
          />
        );
    }
  };

  const handleClick = (direction: 'next' | 'prev') => {
    let newStep = currentStep;

    direction === 'next' ? newStep++ : newStep--;

    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  return (
    <>
      <div className='w-[90%] max-h-[95%] bg-white rounded-3xl p-8 shadow-lg'>
        <h2 className='text-3xl font-bold text-primary-500 uppercase'>
          Create Account
        </h2>
        <h4 className='text-sm text-gray-400'>
          Create an account to manage your store and subsidiaries.
        </h4>
        <div className='container horizontal mt-5'>
          <Stepper steps={steps} currentStep={currentStep} />
          <div className='my-10'>{displayStep(currentStep)}</div>
        </div>

        <StepperControl
          handleClick={handleClick}
          currentStep={currentStep}
          steps={steps}
          handleSubmit={handleSubmit}
          isDisabled={disabledButton}
          isLoading={isLoading}
        />

        {currentStep < 2 ? (
          <div className='flex items-center gap-2 text-xs mt-5 justify-end'>
            <p className='text-gray-400'>Already have an account?</p>
            <Link
              className='text-primary-500 hover:text-primary-600'
              to='/auth/login'>
              Login.
            </Link>
          </div>
        ) : null}
      </div>
    </>
  );
};
