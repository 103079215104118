import { useHttpPrivate } from '@network/api';
import {
  BaseError,
  Endpoints,
  Expenses,
  ICreateExpenseDto,
  ICreateExpenseResponse,
  IGetAllExpensesResponse,
  IGetExpensesPaginatedResponse,
  PaginationData,
  QueryKeys,
} from '@network/interface';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import * as yup from 'yup';

export const useGetExpensesPaginated = () => {
  const httpPrivate = useHttpPrivate();
  const [paginator, setPaginator] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const [expenses, setExpenses] = useState<Expenses[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationData | null>(
    null
  );

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_EXPENSES_PAGINATED],
    async () => {
      const { data } = await httpPrivate.get<IGetExpensesPaginatedResponse>(
        `${Endpoints.GET_ALL_EXPENSES_PAGINATED}?page=${paginator.currentPage}&limit=${paginator.pageSize}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setExpenses(data.data);
        setPaginationData(data?.pagination);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const updatePage = (page?: number, pageSize?: number) => {
    setPaginator((prev) => ({
      currentPage: page || prev.currentPage,
      pageSize: pageSize || prev.pageSize,
    }));
  };

  return { isLoading, expenses, paginationData, paginator, updatePage };
};

export const useGetAllExpenses = () => {
  const httpPrivate = useHttpPrivate();

  const [expenses, setExpenses] = useState<Expenses[]>([]);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_EXPENSES],
    async () => {
      const { data } = await httpPrivate.get<IGetAllExpensesResponse>(
        `${Endpoints.GET_ALL_EXPENSES}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setExpenses(data.data);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  return { isLoading, expenses };
};

export const useCreateExpense = ({
  prev,
  handleCloseModal,
}: {
  prev?: Expenses | null;
  handleCloseModal: () => void;
}) => {
  console.log(prev);
  const httpPrivate = useHttpPrivate();

  const schema = yup.object().shape({
    amount: yup.string().required('This field is required!.'),
    category: yup.string().required('This field is required!.'),
    description: yup.string().required('This field is required!.'),
  });

  const initialValues: ICreateExpenseDto = {
    amount: '',
    category: '',
    description: '',
  };

  useEffect(() => {
    if (prev) {
      setFieldValue('amount', prev?.amount || '');
      setFieldValue('category', prev?.category || '');
      setFieldValue('description', prev?.description || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev]);

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    async (values: ICreateExpenseDto) => {
      const res = await httpPrivate.post<ICreateExpenseResponse>(
        Endpoints.CREATE_EXPENSES,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries([QueryKeys.GET_ALL_EXPENSES_PAGINATED]);
        handleCloseModal();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { isLoading: isEditLoading, mutate: update } = useMutation(
    async (values: ICreateExpenseDto) => {
      const res = await httpPrivate.put<ICreateExpenseResponse>(
        `${Endpoints.EDIT_EXPENSE}/${prev?._expense_id}`,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries([QueryKeys.GET_ALL_EXPENSES_PAGINATED]);
        handleCloseModal();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = (payload: ICreateExpenseDto) => {
    mutate(payload);
  };

  const onEditSubmit = (payload: ICreateExpenseDto) => {
    update(payload);
  };

  const {
    errors,
    handleSubmit,
    handleChange,
    values,
    dirty,
    isValid,
    setFieldValue,
  } = useFormik({
    onSubmit: prev ? onEditSubmit : onSubmit,
    initialValues,
    validationSchema: schema,
  });

  const isDisabled = !dirty || !isValid;

  const isEdit = !!prev;

  return {
    isLoading: isLoading || isEditLoading,
    handleChange,
    errors,
    isDisabled,
    values,
    isEdit,
    handleSubmit,
  };
};
