import { BaseSelect } from '@lib/Select';
import { IRegisterDTO, Package } from '@network/interface';
import { FormikErrors } from 'formik';

interface IProps {
  values: IRegisterDTO;
  handleChange: any;
  errors: FormikErrors<IRegisterDTO>;
  pricingOptions: { key: string; value: string }[];
  selectedPricing: Package | null;
}

export const PricingPackageStep = ({
  errors,
  handleChange,
  values,
  pricingOptions,
  selectedPricing,
}: IProps) => {
  return (
    <div>
      <div className='flex justify-between gap-4'>
        <BaseSelect
          label='Pricing Package'
          placeholder='Select Pricing Package'
          options={pricingOptions}
          name='package'
          onChange={handleChange}
          value={values?.package}
          error={errors?.package}
          disabled={!pricingOptions || pricingOptions.length < 0}
        />
      </div>

      {/* Details about the selected package will be displayed here  */}
      {selectedPricing ? (
        <div className='mt-8 border border-gray-400 flex flex-col items-center p-5 rounded-lg bg-gray-100'>
          <h5 className='text-xl font-bold text-primary-500'>
            Package Information
          </h5>
          <div className='p-4 w-3/4 m-auto flex flex-col gap-3'>
            <div className='flex items-center gap-3'>
              <div className='bg-primary-500 w-5 h-5 flex items-center justify-center rounded-full'>
                <span className='text-white font-bold text-xs'>&#10003;</span>
              </div>
              <p className='text-sm text-gray-500'>
                Number of Staffs ={' '}
                <span className='text-lg font-bold'>
                  {selectedPricing?.number_of_staffs}
                </span>
              </p>
            </div>
            <div className='flex items-center gap-3 '>
              <div className='bg-primary-500 w-5 h-5 flex items-center justify-center rounded-full'>
                <span className='text-white font-bold text-xs'>&#10003;</span>
              </div>
              <p className='text-sm text-gray-500'>
                Number of Branches ={' '}
                <span className='text-lg font-bold'>
                  {selectedPricing?.number_of_branches}
                </span>
              </p>
            </div>
            <div className='flex items-center gap-3 '>
              <div className='bg-primary-500 w-5 h-5 flex items-center justify-center rounded-full'>
                <span className='text-white font-bold text-xs'>&#10003;</span>
              </div>
              <p className='text-sm text-gray-500'>
                Duration ={' '}
                <span className='text-lg font-bold'>
                  {selectedPricing?.duration} {selectedPricing?.period}(s)
                </span>
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
