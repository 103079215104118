import { Spinner } from '@lib/Loaders';
import React from 'react';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  label?: string;
  loadingText?: string;
  spinnerColor?: string;
  onClick?: (e?: any) => void;
  pale?: boolean;
}

export const Button = ({
  isLoading,
  label,
  loadingText,
  spinnerColor,
  ...props
}: IProps) => {
  return (
    <button
      disabled={props.disabled || isLoading}
      {...props}
      className={`py-4 px-5 mr-2 text-sm font-medium text-white bg-primary-500 rounded-lg hover:bg-primary-600 inline-flex items-center w-full justify-center disabled:opacity-30 ${
        props.pale
          ? '!bg-transparent !text-gray-300 !border !border-gray-300'
          : ''
      }${props.className}`}>
      {isLoading ? (
        <div className='flex items-center gap-3'>
          <Spinner color={spinnerColor} />
          {loadingText ? <p>{loadingText}</p> : null}
        </div>
      ) : (
        label
      )}
    </button>
  );
};
