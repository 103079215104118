import { ArrowDown, ArrowUp } from '@assets';
import React from 'react';

interface IProps {
  icon: React.ReactElement;
  label: string;
  total: string;
  isUpTrend: boolean;
  trendPercentage: string;
}

export const DashboardCard = ({
  icon,
  label,
  total,
  isUpTrend,
  trendPercentage,
}: IProps) => {
  return (
    <div className='rounded-md border border-slate-300 dark:border-slate-500 bg-white dark:bg-slate-800 py-4 px-6 shadow-xl'>
      <div className='flex h-12 w-12 items-center justify-center rounded-full bg-slate-200 dark:bg-slate-700'>
        {icon}
      </div>

      <div className='mt-4 flex items-end justify-between'>
        <div>
          <h4 className='text-2xl font-bold text-black dark:text-white'>{total}</h4>
          <span className='text-sm text-gray-400 dark:text-slate-500 font-medium'>{label}</span>
        </div>

        <span className={`flex items-center gap-1 text-sm font-medium italic ${isUpTrend ? "text-green-600" : "text-red-600"}`}>
          {trendPercentage}
          {isUpTrend ? (
            <ArrowUp className='fill-green-600' />
          ) : (
            <ArrowDown className='fill-red-600' />
          )}
        </span>
      </div>
    </div>
  );
};
