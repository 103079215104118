import { useAppSelector } from '@hooks/useAppSelector';
import { AdminLayout, AuthLayout } from '@layout/index';
import {
  AccountSettingsPage,
  BranchManagementPage,
  CategoryManagementPage,
  CustomerManagementPage,
  DashboardPage,
  ExpensesManagementPage,
  ForgotPasswordPage,
  LoginPage,
  ProductManagementPage,
  ProfilePage,
  RegisterPage,
  ReportsManagementPage,
  RoleManagementPage,
  SalesManagementPage,
  StaffManagementPage,
  SuccessPage,
  SuppliersManagementPage,
  UpdatePasswordPage,
  VerifyAccountPage,
} from '@pages';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

export const AppRouter = () => {
  const { token } = useAppSelector((state) => state.user);

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Navigate to='/auth/login' />} />
        <Route
          path='/auth'
          element={token ? <Navigate to='/store/dashboard' /> : <AuthLayout />}>
          <Route path='login' element={<LoginPage />} />
          <Route path='register' element={<RegisterPage />} />
          <Route path='forgot-password' element={<ForgotPasswordPage />} />
          <Route path='verify-account' element={<VerifyAccountPage />} />
          <Route path='account-success' element={<SuccessPage />} />
          <Route path='password-update' element={<UpdatePasswordPage />} />
        </Route>
        {/* <Route
          path='/store'
          element={token ? <AdminLayout /> : <Navigate to='/auth/login' />}>
          <Route path='dashboard' element={<DashboardPage />} />
          <Route path='staff-management' element={<StaffManagementPage />} />
          <Route path='role-management' element={<RoleManagementPage />} />
          <Route path='manage-inventories' element={<ManageInventories />} />
          <Route path='track-inventory' element={<TrackInventories />} />
          <Route path='branch-management' element={<BranchManagementPage />} />
          <Route path='manage-products' element={<ProductManagementPage />} />
          <Route
            path='manage-products-category'
            element={<CategoryManagementPage />}
          />
        </Route> */}
        <Route path='/store' element={<AdminLayout />}>
          <Route path='dashboard' element={<DashboardPage />} />
          <Route path='staff-management' element={<StaffManagementPage />} />
          <Route path='role-management' element={<RoleManagementPage />} />
          <Route path='branch-management' element={<BranchManagementPage />} />
          <Route path='manage-products' element={<ProductManagementPage />} />
          <Route path='manage-sales' element={<SalesManagementPage />} />
          <Route path='manage-expenses' element={<ExpensesManagementPage />} />
          <Route path='manage-customers' element={<CustomerManagementPage />} />
          <Route path='store-reports' element={<ReportsManagementPage />} />
          <Route
            path='manage-suppliers'
            element={<SuppliersManagementPage />}
          />
          <Route
            path='manage-products-category'
            element={<CategoryManagementPage />}
          />
          <Route path='profile' element={<ProfilePage />} />
          <Route path='settings' element={<AccountSettingsPage />} />
        </Route>
      </Routes>
    </Router>
  );
};
