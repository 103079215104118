import { useAppSelector } from '@hooks/useAppSelector';
import { httpClient } from '@network/api';
import { AxiosInstance } from 'axios';
import { useEffect } from 'react';

export const useHttpPrivate = (): AxiosInstance => {
  const user = useAppSelector((state) => state.user);
  useEffect(() => {
    const requestInterceptor = httpClient.interceptors.request.use(
      async (config: any) => {
        const accessToken = user?.token;
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (err: any) => {
        return Promise.reject(err);
      }
    );
    const responseInterceptor = httpClient.interceptors.response.use(
      async (response) => {
        return response;
      },
      async (err: any) => {
        const { config, response } = err;
        if (response?.status === 401 && !config?.__isRetryRequest) {
          config.__isRetryRequest = true;
          // refresh access token
          return httpClient(config);
        }
        return Promise.reject(err);
      }
    );

    return () => {
      httpClient.interceptors.response.eject(responseInterceptor);
      httpClient.interceptors.request.eject(requestInterceptor);
    };
  }, [user]);

  return httpClient;
};
