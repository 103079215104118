import { BagIcon, CartIcon, UsersIcon } from '@assets';
import { useAppSelector } from '@hooks/useAppSelector';
import { Spinner } from '@lib/Loaders';
import { useGetDashboardDetails } from '@network/hooks/dashboard.network';
import { DashboardCard, LineChart } from './components';

export const DashboardPage = () => {
  const { username } = useAppSelector((state) => state.user);
  const { dashboardDetails, isLoading } = useGetDashboardDetails();
  return (
    <div>
      <div className='my-7'>
        <p className='text-sm text-gray-400 font-medium'>Welcome back,</p>
        <h3 className='text-2xl font-bold uppercase dark:text-white'>
          {username}
        </h3>
      </div>
      {isLoading ? (
        <div className='w-full h-[40vh] flex items-center justify-center'>
          <Spinner />
        </div>
      ) : (
        <>
          <div className='grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7'>
            <DashboardCard
              trendPercentage={`${
                dashboardDetails?.analysis?.total?.products?.percentage ?? 0
              }%`}
              isUpTrend={true}
              label='Total Products'
              total={`${
                dashboardDetails?.analysis?.total?.products?.total ?? 0
              }`}
              icon={<BagIcon className='fill-cyan-600' />}
            />
            {/* <DashboardCard
              trendPercentage={`${dashboardDetails?.analysis?.total?.transactions?.percentage}%`}
              isUpTrend={true}
              label='Total Transactions'
              total={`NGN ${dashboardDetails?.analysis?.total?.transactions?.percentage}%`}
              icon={<CartIcon className='fill-orange-600' />}
            /> */}
            <DashboardCard
              trendPercentage={`${
                dashboardDetails?.analysis?.total?.sales?.percentage ?? 0
              }%`}
              isUpTrend={true}
              label='Total Sales'
              total={`NGN ${
                dashboardDetails?.analysis?.total?.sales?.total ?? 0
              }`}
              icon={<CartIcon className='fill-indigo-600' />}
            />
            <DashboardCard
              trendPercentage={`${
                dashboardDetails?.analysis?.total?.customers?.percentage ?? 0
              }%`}
              isUpTrend={true}
              label='Total Customers'
              total={`${
                dashboardDetails?.analysis?.total?.customers?.total ?? 0
              }`}
              icon={<UsersIcon className='fill-green-600' />}
            />
          </div>
          <div className='grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7 mt-10 2xl:gap-7'>
            <div className='col-span-12 border border-slate-300 dark:border-slate-500 bg-white px-5 pt-7 pb-5 shadow-lg sm:px-7 xl:col-span-8 rounded-md dark:bg-slate-800'>
              <div className='flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap'>
                <div className='flex w-full flex-wrap gap-3 sm:gap-5'>
                  <div className='flex'>
                    <span className='mt-1 mr-2 flex h-5 w-5 items-center justify-center rounded-full border border-primary-500'>
                      <span className='block h-2 w-2 rounded-full bg-primary-500'></span>
                    </span>
                    <div className=''>
                      <p className='font-semibold text-primary-500'>
                        Total Revenue
                      </p>
                      <p className='text-sm font-medium'>
                        12.04.2022 - 12.05.2022
                      </p>
                    </div>
                  </div>
                  <div className='flex'>
                    <span className='mt-1 mr-2 flex h-5 w-5 items-center justify-center rounded-full border border-green-600'>
                      <span className='block h-2 w-2 rounded-full bg-green-600'></span>
                    </span>
                    <div className=''>
                      <p className='font-semibold text-green-600'>
                        Total Sales
                      </p>
                      <p className='text-sm font-medium'>
                        12.04.2022 - 12.05.2022
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className='flex w-64 gap-2 justify-end'>
                  <div className='inline-flex w-full justify-between items-center rounded-md bg-slate-100 p-1.5 dark:bg-slate-700'>
                    <button className='rounded bg-white py-2 px-3 text-xs font-medium text-black shadow-md hover:bg-white hover:shadow-md dark:bg-slate-500 dark:text-white dark:hover:bg-slate-600'>
                      Day
                    </button>
                    <button className='rounded py-2 px-3 text-xs font-medium text-black hover:bg-white hover:shadow-md dark:text-white dark:hover:bg-slate-600'>
                      Week
                    </button>
                    <button className='rounded py-2 px-3 text-xs font-medium text-black hover:bg-white hover:shadow-md dark:text-white dark:hover:bg-slate-600'>
                      Month
                    </button>
                  </div>
                </div> */}
              </div>

              <div>
                <div id='chartOne' className='-ml-5'>
                  <LineChart data={dashboardDetails?.analysis?.chart} />
                </div>
              </div>
            </div>
            {/* <div className='col-span-12 border border-slate-300 dark:border-slate-500 dark:bg-slate-800 bg-white px-5 pt-7 pb-5 shadow-lg sm:px-7 xl:col-span-4 rounded-md'>
              <div>
                <BarChart />
              </div>
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};
