import { PageHeader } from '@components/PageHeader';

export const AccountSettingsPage = () => {
  //   const { user } = useAppSelector((state) => state);
  return (
    <div>
      <PageHeader title='Account Setting' />
    </div>
  );
};
