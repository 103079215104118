import { MOMENT_FORMAT } from '@app/helpers';
import ConfirmationModal from '@components/ConfirmDeleteModal';
import { useDisclosure } from '@hooks/useDisclosure';
import { Button } from '@lib/Button';
import { Table, TableActionMenu } from '@lib/Table';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { useGetProductsPaginated } from '@network/hooks';
import { Product } from '@network/interface';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { CreateProductModal } from '../modals';

export const ProductTable = () => {
  const {
    isOpen: isOpenAddProductModal,
    onClose: onCloseAddProductModal,
    onOpen: onOpenAddProductModal,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();

  const [selected, setSelected] = useState<Product | null>(null);

  const handleEditClick = (val: Product) => {
    setSelected(val);
    onOpenAddProductModal();
  };

  const handleDeleteClick = (val: Product) => {
    setSelected(val);
    onDeleteOpen();
  };

  const confirmDelete = () => {
    // call delete function from delete hook
  };

  const columns = useMemo<ColumnDef<Product>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: '_product_id',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Product Name',
        accessorKey: 'name',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Category',
        accessorKey: '_category.name',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Quantity',
        accessorKey: 'quantity',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: (info) => (
          <span
            className={`px-3 py-1 text-xs rounded-md text-white ${
              info.getValue() === 'ACTIVE'
                ? 'bg-green-500'
                : info.getValue() === 'INACTIVE'
                ? 'bg-red-500'
                : ''
            }`}>
            {info.getValue() as string}
          </span>
        ),
      },
      {
        header: 'Date Added',
        accessorKey: 'createdAt',
        cell: (info) => moment(info.getValue() as string).format(MOMENT_FORMAT),
      },
      {
        header: 'Last Updated',
        accessorKey: 'updatedAt',
        cell: (info) => moment(info.getValue() as string).format(MOMENT_FORMAT),
      },
      {
        header: 'Action',
        accessorFn: (row) => row,
        cell: (info) => {
          return (
            <TableActionMenu
              actions={['DELETE', 'EDIT']}
              actionHandler={{
                handleDelete: handleDeleteClick,
                handleEdit: handleEditClick,
              }}
              value={info.cell.getValue() as string}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { products, isLoading, paginationData, updatePage } =
    useGetProductsPaginated();

  return (
    <>
      {/* <div>
        <BaseSelect
          options={[10, 20, 30, 40, 50, 100].map((val) => ({
            key: val.toString(),
            value: val.toString(),
          }))}
        />
      </div> */}
      <div className='w-full bg-white flex items-center justify-end gap-5 my-5 rounded-md border border-slate-200 p-4 dark:border-slate-300'>
        <div className='w-[15%]'>
          <Button
            label='Add Product'
            onClick={() => {
              setSelected(null);
              onOpenAddProductModal();
            }}
          />
        </div>
      </div>
      <Table
        columns={columns}
        data={products}
        totalPageCount={paginationData?.total_pages}
        updatePage={updatePage}
        isLoading={isLoading}
      />

      <CreateProductModal
        isOpen={isOpenAddProductModal}
        onClose={onCloseAddProductModal}
        prev={selected}
      />

      <ConfirmationModal
        icon={
          <div className='w-20 h-20 rounded-full bg-slate-200 flex items-center justify-center'>
            <DeleteForeverOutlined color='error' fontSize='large' />
          </div>
        }
        onSubmit={confirmDelete}
        loading={false}
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        title='Delete Product'
        description={`Are you sure you want to delete this product {${selected?.name}}. This action cannot be undone once performed?`}
      />
    </>
  );
};
