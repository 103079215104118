import { DragAndDropImageUpload } from '@lib/DragAndDropImageUpload';
import { IRegisterDTO } from '@network/interface';
import { FormikErrors } from 'formik';
import { Link } from 'react-router-dom';

interface IProps {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  values: IRegisterDTO;
  handleChange: any;
  errors: FormikErrors<IRegisterDTO>;
}
export const AdditionalInformationStep = ({
  errors,
  handleChange,
  setFieldValue,
  values,
}: IProps) => {
  console.log(values);
  return (
    <div>
      <p className='text-sm text-gray-400 mb-1'>Upload Store Logo</p>
      <DragAndDropImageUpload />

      <label className='flex items-center gap-4 text-gray-400 text-sm mt-2'>
        <input
          type='checkbox'
          checked={values.terms_and_condition}
          name='terms_and_condition'
          onChange={handleChange}
        />

        <p>
          I agree to the company's
          <Link
            to='/terms-and-conditions'
            className='ml-2 underline underline-offset-4 text-primary-500'>
            Terms and Conditions
          </Link>
        </p>
      </label>
      {errors?.terms_and_condition ? (
        <small className={`ml-1 text-red-500 text-xs`}>
          {errors?.terms_and_condition}
        </small>
      ) : null}
      <label className='flex items-center gap-4 text-gray-400 text-sm mt-2'>
        <input
          type='checkbox'
          checked={values.newsletter}
          onChange={handleChange}
          name='newsletter'
        />
        <p>I would like to receive newsletters and updates.</p>
      </label>
    </div>
  );
};
