import { MOMENT_FORMAT } from '@app/helpers';
import { Table } from '@lib/Table';
import { useGetRolesPaginated } from '@network/hooks';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';

export const RoleTable = () => {
  const { isLoading, paginationData, roles, updatePage } =
    useGetRolesPaginated();

  const columns: ColumnDef<{
    branchID: number;
    branchName: string;
    branchAddress: string;
    date: string;
    status: string;
    selected: false;
  }>[] = [
    {
      header: 'Role',
      accessorKey: 'name',
    },
    {
      header: 'Description',
      accessorKey: 'description',
    },
    {
      header: 'Date Added',
      accessorKey: 'createdAt',
      cell: (info) => moment(info.getValue() as string).format(MOMENT_FORMAT),
    },
    {
      header: 'Last Updated',
      accessorKey: 'updatedAt',
      cell: (info) => moment(info.getValue() as string).format(MOMENT_FORMAT),
    },
    // {
    //   header: 'Status',
    //   accessorKey: 'status',
    //   cell: (info) => (
    //     <span
    //       className={`p-1 text-xs rounded-md text-white ${
    //         info.getValue() === 'ACTIVE'
    //           ? 'bg-green-500'
    //           : info.getValue() === 'INACTIVE'
    //           ? 'bg-red-500'
    //           : ''
    //       }`}>
    //       {info.getValue() as string}
    //     </span>
    //   ),
    // },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={roles}
        totalPageCount={paginationData?.total_pages}
        updatePage={updatePage}
        isLoading={isLoading}
      />
    </>
  );
};
