import { Button } from '@lib/Button';
import { BaseInput } from '@lib/Input';
import { Modal, ModalFooter } from '@lib/Modal';
import { BaseSelect } from '@lib/Select';
import { useCreateProduct } from '@network/hooks';
import { Product } from '@network/interface';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  prev?: Product | null;
}

export const CreateProductModal = ({ isOpen, onClose, prev }: IProps) => {
  const {
    isLoading,
    isEdit,
    handleSubmit,
    errors,
    values,
    handleChange,
    isDisabled,
    statusOptions,
    branchOptions,
    categoryOptions,
    handleImageChange,
    imagePreviews,
    supplierOptions,
  } = useCreateProduct({ prev, handleCloseModal: onClose });
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isEdit ? `Edit Product: ${prev?.name}` : 'Add Product'}>
      <div className='p-4'>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Product Name'
            placeholder='Enter Product Name'
            name='name'
            type='text'
            onChange={handleChange}
            value={values.name}
            error={errors.name}
          />
          <BaseInput
            label='Product Quantity'
            placeholder='Enter Quantity'
            name='quantity'
            type='number'
            onChange={handleChange}
            value={values.quantity}
            error={errors.quantity}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Product Price'
            placeholder='Enter Product Price'
            name='price'
            type='number'
            onChange={handleChange}
            value={values.price}
            error={errors.price}
          />
          <BaseInput
            label='Product Barcode'
            placeholder='Enter barcode'
            name='barcode'
            type='number'
            onChange={handleChange}
            value={values.barcode}
            error={errors.barcode}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseSelect
            label='Product Category'
            placeholder='Select Product Category'
            options={categoryOptions}
            name='category'
            type='category'
            onChange={handleChange}
            value={values.category}
            error={errors.category}
            disabled={!categoryOptions || categoryOptions.length < 0}
          />
          <BaseSelect
            label='Branch'
            placeholder='Select Branch'
            options={branchOptions}
            name='branch'
            type='branch'
            onChange={handleChange}
            value={values.branch}
            error={errors.branch}
            disabled={!branchOptions || branchOptions.length < 0}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseSelect
            label='Product Supplier'
            placeholder='Select Product Supplier'
            options={supplierOptions}
            name='supplier'
            onChange={handleChange}
            value={values.supplier}
            error={errors.supplier}
          />
          <BaseSelect
            label='Product Status'
            placeholder='Select Product Status'
            options={statusOptions}
            name='status'
            onChange={handleChange}
            value={values.status}
            error={errors.status}
          />
        </div>
        <div>
          <BaseInput
            label='Product Images'
            placeholder='Select Product Images'
            type='file'
            onChange={handleImageChange}
            multiple
            accept='image/*'
          />

          <div className='flex flex-wrap gap-2'>
            {imagePreviews.map((blobUrl: any) => (
              <div className='relative'>
                <img
                  key={blobUrl}
                  src={blobUrl}
                  alt='Uploaded-product'
                  className='w-16 h-16 object-cover rounded'
                />
                <div></div>
              </div>
            ))}
          </div>
        </div>
        <BaseInput
          label='Note'
          placeholder='Enter note'
          name='note'
          type='text'
          onChange={handleChange}
          value={values.note}
          error={errors.note}
        />
      </div>

      <ModalFooter>
        <Button
          label={isEdit ? 'Save Product' : 'Create Product'}
          isLoading={isLoading}
          disabled={isDisabled}
          onClick={handleSubmit}
          type='submit'
        />
      </ModalFooter>
    </Modal>
  );
};
