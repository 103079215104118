import { Button } from '@lib/Button';
import { BaseInput } from '@lib/Input';
import { Modal, ModalFooter } from '@lib/Modal';
import { BaseSelect } from '@lib/Select';
import { useCreateCustomer } from '@network/hooks';
import { Customer } from '@network/interface';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  prev?: Customer | null;
}

export const CreateCustomerModal = ({ isOpen, onClose, prev }: IProps) => {
  const {
    isLoading,
    isEdit,
    handleSubmit,
    errors,
    values,
    handleChange,
    isDisabled,
    statusOptions,
  } = useCreateCustomer({ prev, handleCloseModal: onClose });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isEdit ? `Editing Customer: ${prev?.name}` : 'Add Customer'}>
      <div className='p-4'>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Full Name'
            placeholder='Enter Full Name'
            name='name'
            type='text'
            onChange={handleChange}
            value={values.name}
            error={errors.name}
          />
          <BaseInput
            label='Email'
            placeholder='Enter Email'
            name='email'
            type='email'
            onChange={handleChange}
            value={values.email}
            error={errors.email}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Address'
            placeholder='Enter Address'
            name='address'
            type='text'
            onChange={handleChange}
            value={values.address}
            error={errors.address}
          />
          <BaseInput
            label='Phone Number'
            placeholder='Enter Phone Number'
            name='phone'
            type='number'
            onChange={handleChange}
            value={values.phone}
            error={errors.phone}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseSelect
            label='Status'
            placeholder='Select Status'
            options={statusOptions}
            name='status'
            type='text'
            onChange={handleChange}
            value={values.status}
            error={errors.status}
          />
        </div>
      </div>
      <ModalFooter>
        <Button
          label={isEdit ? 'Save Customer' : 'Create Customer'}
          isLoading={isLoading}
          disabled={isDisabled}
          onClick={handleSubmit}
          type='submit'
        />
      </ModalFooter>
    </Modal>
  );
};
