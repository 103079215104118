import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// import Logo from '../images/logo/logo.svg';
import {
  CalendarIcon,
  ChevronArrowDown,
  CubeLogo,
  DashboardIcon,
} from '@assets';
import { useAppSelector } from '@hooks/useAppSelector';
import SidebarLinkGroup from './SidebarLinkGroup';

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const location = useLocation();
  const { pathname } = location;
  const { _store } = useAppSelector((state) => state.user);

  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded');
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);
  // console.log(pathname);
  const navData = [
    {
      title: 'Dashboard',
      link: '/store/dashboard',
      isActive: pathname === '/store/dashboard',
      icon: <DashboardIcon className='fill-current' />,
    },
    {
      title: 'Staff Management',
      link: '#',
      isGroup: true,
      isActive:
        pathname === '/store/staff-management' ||
        pathname === '/store/role-management',
      icon: <CalendarIcon className='fill-current' />,
      sub: [
        {
          title: 'Manage Staffs',
          link: '/store/staff-management',
        },
        {
          title: 'Manage Roles',
          link: '/store/role-management',
        },
      ],
    },
    // {
    //   title: 'Inventory Tracking',
    //   link: '/',
    //   isGroup: true,
    //   icon: <CalendarIcon className='fill-current' />,
    //   sub: [
    //     {
    //       title: 'Manage Inventories',
    //       link: '/store/manage-inventories',
    //     },
    //     {
    //       title: 'Track Inventory',
    //       link: '/store/track-inventory',
    //     },
    //   ],
    // },
    {
      title: 'Branch Management',
      link: '/',
      isGroup: true,
      icon: <CalendarIcon className='fill-current' />,
      sub: [
        {
          title: 'Manage Branches',
          link: '/store/branch-management',
        },
      ],
    },
    {
      title: 'Product Management',
      link: '/',
      isGroup: true,
      icon: <CalendarIcon className='fill-current' />,
      sub: [
        {
          title: 'Manage Products',
          link: '/store/manage-products',
        },
        {
          title: 'Manage Products Category',
          link: '/store/manage-products-category',
        },
      ],
    },
    {
      title: 'Sales Management',
      link: '/',
      isGroup: true,
      icon: <CalendarIcon className='fill-current' />,
      sub: [
        {
          title: 'Manage Sales',
          link: '/store/manage-sales',
        },
      ],
    },
    {
      title: 'Expenses Management',
      link: '/',
      isGroup: true,
      icon: <CalendarIcon className='fill-current' />,
      sub: [
        {
          title: 'Manage Expenses',
          link: '/store/manage-expenses',
        },
      ],
    },
    {
      title: 'Customer Management',
      link: '/',
      isGroup: true,
      icon: <CalendarIcon className='fill-current' />,
      sub: [
        {
          title: 'Manage Customers',
          link: '/store/manage-customers',
        },
      ],
    },
    {
      title: 'Supply Management',
      link: '/',
      isGroup: true,
      icon: <CalendarIcon className='fill-current' />,
      sub: [
        {
          title: 'Manage Suppliers',
          link: '/store/manage-suppliers',
        },
      ],
    },
    {
      title: 'Report Management',
      link: '/',
      isGroup: true,
      icon: <CalendarIcon className='fill-current' />,
      sub: [
        {
          title: 'Manage Reports',
          link: '/store/store-reports',
        },
      ],
    },
    // {
    //   title: 'Settings',
    //   link: '/',
    //   isGroup: true,
    //   icon: <CalendarIcon className='fill-current' />,
    //   sub: [
    //     {
    //       title: 'Manage Subscription',
    //       link: '/store/subscription',
    //     },
    //   ],
    // },
  ];

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-[99] flex h-screen w-[16rem] flex-col overflow-y-hidden bg-white dark:bg-slate-800 duration-300 ease-linear lg:static lg:translate-x-0 px-2 ${
        sidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}>
      {/* <!-- SIDEBAR HEADER --> */}
      <div className='flex items-center justify-between gap-2 py-5 lg:py-6'>
        <NavLink to='/' className=''>
          <img src={CubeLogo} alt='Logo' className='w-16' />
        </NavLink>

        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls='sidebar'
          aria-expanded={sidebarOpen}
          className='block lg:hidden'>
          <svg
            className='fill-red-500'
            width='20'
            height='18'
            viewBox='0 0 20 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z'
              fill=''
            />
          </svg>
        </button>
      </div>

      <p className='text-center uppercase font-bold text-primary-500 text-lg'>
        {_store?.name}
      </p>
      {/* <!-- SIDEBAR HEADER --> */}

      <div className='no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear'>
        {/* <!-- Sidebar Menu --> */}
        <nav className='mt-1 py-1 lg:mt-2'>
          {/* <!-- Menu Group --> */}
          <div>
            <ul className='mb-6 flex flex-col gap-1.5'>
              {/* <!-- Menu Item Dashboard --> */}
              {navData.map((nav) => (
                <>
                  {nav?.isGroup ? (
                    <SidebarLinkGroup activeCondition={nav?.isActive ?? false}>
                      {(handleClick, open) => {
                        // console.log(open);
                        return (
                          <React.Fragment>
                            <NavLink
                              to='#'
                              className={`group relative flex items-center text-sm text-gray-500 gap-2.5 rounded-sm py-3 px-4 font-medium duration-300 ease-in-out ${
                                open ? '!text-primary-500' : ''
                              } ${
                                nav?.isActive
                                  ? 'bg-primary-100 text-primary-500 text-sm'
                                  : ''
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                sidebarExpanded
                                  ? handleClick()
                                  : setSidebarExpanded(true);
                              }}>
                              <div
                                className={`w-1 h-[1.5rem] ${
                                  nav?.isActive
                                    ? 'w-1 h-[1.5rem] bg-primary-500 rounded-full'
                                    : ''
                                }`}
                              />
                              {nav?.icon}
                              {nav?.title}
                              <ChevronArrowDown
                                className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
                                  open && 'rotate-180'
                                }`}
                              />
                            </NavLink>
                            {/* <!-- Dropdown Menu Start --> */}
                            <div
                              className={`translate transform overflow-hidden ${
                                !open && 'hidden'
                              }`}>
                              <ul className='mt-1 mb-5.5 flex flex-col gap-2.5 pl-6'>
                                {nav?.sub.map((sub) => (
                                  <li>
                                    <NavLink
                                      to={sub?.link}
                                      className={({ isActive }) =>
                                        'relative flex items-center text-sm gap-2.5 text-gray-500 rounded-md px-4 font-medium duration-300 ease-in-out' +
                                        (isActive ? '!text-white' : '')
                                      }>
                                      {sub?.title}
                                    </NavLink>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            {/* <!-- Dropdown Menu End --> */}
                          </React.Fragment>
                        );
                      }}
                    </SidebarLinkGroup>
                  ) : (
                    <li>
                      <NavLink
                        to={nav?.link}
                        className={`relative flex items-center gap-2.5 rounded-md py-3 px-4 font-medium duration-300 ease-in-out text-gray-500  ${
                          nav?.isActive
                            ? 'bg-primary-100 !text-primary-500 text-sm'
                            : ''
                        }`}>
                        <div
                          className={`w-1 h-[1.5rem] ${
                            nav?.isActive
                              ? 'w-1 h-[1.5rem] bg-primary-500 rounded-full'
                              : ''
                          }`}
                        />
                        {nav?.icon}
                        {nav?.title}
                      </NavLink>
                    </li>
                  )}
                </>
              ))}
            </ul>
          </div>
        </nav>
        {/* <!-- Sidebar Menu --> */}
      </div>
    </aside>
  );
};

export default Sidebar;
