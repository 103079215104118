import { BaseInput } from '@lib/Input';
import { BaseSelect } from '@lib/Select';
import { IRegisterDTO } from '@network/interface';
import { FormikErrors } from 'formik';

interface IProps {
  values: IRegisterDTO;
  handleChange: any;
  errors: FormikErrors<IRegisterDTO>;
  businessOptions: { key: string; value: string }[];
}

export const StoreInformationStep = ({
  errors,
  handleChange,
  values,
  businessOptions,
}: IProps) => {
  return (
    <div>
      <div className='flex justify-between gap-4'>
        <BaseInput
          label='Store Name'
          name='storename'
          placeholder='Enter your store name'
          type='text'
          required
          onChange={handleChange}
          value={values.storename}
          error={errors.storename}
        />
        <BaseSelect
          label='Business Type'
          placeholder='Select Business Type'
          options={businessOptions}
          name='business'
          type='business'
          onChange={handleChange}
          value={values.business}
          error={errors.business}
          disabled={!businessOptions || businessOptions.length < 0}
        />
      </div>
      <div className='flex justify-between gap-4'>
        <BaseInput
          label='Store Address'
          name='address'
          placeholder='Enter your store address'
          type='text'
          onChange={handleChange}
          value={values.address}
          error={errors.address}
        />
      </div>
      <div className='flex justify-between gap-4'>
        <BaseInput
          label='Website Url'
          name='website_url'
          placeholder='Enter your store website url'
          type='text'
          optional
          value={values.website_url}
          error={errors.website_url}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
