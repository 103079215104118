import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';

interface ICountries {
  error: boolean;
  msg: string;
  data: Array<{
    name: string;
    iso3: string;
    states: Array<{
      name: string;
      state_code: string;
    }>;
  }>;
}

interface IStates {
  error: false;
  msg: 'states in Colombia retrieved';
  data: {
    name: string;
    iso3: string;
    iso2: string;
    states: Array<{
      name: string;
      state_code: string;
    }>;
  };
}

interface ICities {
  error: boolean;
  msg: string;
  data: Array<string>;
}

export const useGetCountriesAndStates = ({
  country,
  state,
}: {
  country: string;
  state: string;
}) => {
  const endpoint = 'https://countriesnow.space/api/v0.1/countries/states';
  const city_endpoint =
    'https://countriesnow.space/api/v0.1/countries/state/cities';

  const [countries, setCountries] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);

  const [cities, setCities] = useState<string[]>([]);

  const { isLoading: isLoadingCountries } = useQuery(
    ['APP_COUNTRIES'],
    async () => {
      const { data } = await axios.get<ICountries>(`${endpoint}`);
      return data;
    },
    {
      onSuccess: (data) => {
        setCountries(
          data?.data
            .map((count) => count.name)
            .sort((a, b) => {
              if (a === 'Nigeria') {
                return -1; // Move "Nigeria" to the beginning
              }
              if (b === 'Nigeria') {
                return 1; // Keep "Nigeria" ahead of other countries
              }
              return 0; // Maintain the original order of other countries
            })
        );
      },
    }
  );

  const { mutate: fetchStates, isLoading: isLoadingStates } = useMutation(
    async () => {
      const { data } = await axios.post<IStates>(`${endpoint}`, { country });
      return data;
    },
    {
      onSuccess: (data) => {
        setStates(data?.data?.states.map((state) => state.name));
      },
    }
  );

  const { mutate: fetchCities, isLoading: isLoadingCities } = useMutation(
    async () => {
      const { data } = await axios.post<ICities>(`${city_endpoint}`, {
        country,
        state,
      });
      return data;
    },
    {
      onSuccess: (data) => {
        setCities(data?.data);
      },
    }
  );

  useEffect(() => {
    if (countries) {
      fetchStates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (state && countries) {
      fetchCities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, country]);

  return {
    isLoadingCountries,
    cities,
    countries,
    states,
    isLoadingCities,
    isLoadingStates,
  };
};
