import { MOMENT_FORMAT } from '@app/helpers';
import ConfirmationModal from '@components/ConfirmDeleteModal';
import { useDisclosure } from '@hooks/useDisclosure';
import { Button } from '@lib/Button';
import { Table, TableActionMenu } from '@lib/Table';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { useDeleteStaff, useGetStaffsPaginated } from '@network/hooks';
import { Staff } from '@network/interface';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import { useState } from 'react';
import { CreateStaffModal } from '../modals';

export const StaffTable = () => {
  const {
    isOpen: isOpenAddStaffModal,
    onClose: onCloseAddStaffModal,
    onOpen: onOpenAddStaffModal,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();

  const { handleDelete, isLoading: isDeleteLoading } = useDeleteStaff({
    callback: () => onDeleteClose(),
  });

  const [selected, setSelected] = useState<Staff | null>(null);

  const handleEditClick = (val: Staff) => {
    setSelected(val);
    onOpenAddStaffModal();
  };

  const handleViewClick = (val: Staff) => {
    setSelected(val);
  };

  const handleDeleteClick = (val: Staff) => {
    setSelected(val);
    onDeleteOpen();
  };

  const confirmDelete = () => {
    handleDelete(selected?._account_id!);
  };

  const { isLoading, paginationData, staffs, updatePage } =
    useGetStaffsPaginated();
  const columns: ColumnDef<Staff>[] = [
    {
      header: 'First Name',
      accessorKey: 'firstname',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Last Name',
      accessorKey: 'lastname',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Email Address',
      accessorKey: 'email',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Phone No.',
      accessorKey: 'phone',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (info) => (
        <span
          className={`px-3 py-1 text-xs rounded-md text-white ${
            info.getValue() === 'ACTIVE'
              ? 'bg-green-500'
              : info.getValue() === 'BLOCKED'
              ? 'bg-red-500'
              : info.getValue() === 'PENDING_VERIFICATION'
              ? 'bg-yellow-500'
              : info.getValue() === 'INACTIVE'
              ? 'bg-gray-500'
              : ''
          }`}>
          {info.getValue() as string}
        </span>
      ),
    },
    {
      header: 'Last Updated',
      accessorKey: 'updatedAt',
      cell: (info) => moment(info.getValue() as string).format(MOMENT_FORMAT),
    },
    {
      header: 'Action',
      accessorFn: (row) => row,
      cell: (info) => {
        return (
          <TableActionMenu
            actions={['VIEW', 'DELETE', 'EDIT']}
            actionHandler={{
              handleView: handleViewClick,
              handleEdit: handleEditClick,
              handleDelete: handleDeleteClick,
            }}
            value={info.cell.getValue() as string}
          />
        );
      },
    },
  ];

  return (
    <>
      <div className='w-full bg-white flex items-center justify-end gap-5 my-5 rounded-md border border-slate-200 p-4 dark:border-slate-300'>
        <div className='w-[15%]'>
          <Button
            label='Create Staff'
            onClick={() => {
              setSelected(null);
              onOpenAddStaffModal();
            }}
          />
        </div>
      </div>
      <Table
        columns={columns}
        data={staffs}
        totalPageCount={paginationData?.total_pages}
        updatePage={updatePage}
        isLoading={isLoading}
      />
      {/* view modal */}
      {/* create & edit modal */}
      <CreateStaffModal
        isOpen={isOpenAddStaffModal}
        onClose={onCloseAddStaffModal}
        prev={selected}
      />
      {/* confirm delete modal */}
      <ConfirmationModal
        icon={
          <div className='w-20 h-20 rounded-full bg-slate-200 flex items-center justify-center'>
            <DeleteForeverOutlined color='error' fontSize='large' />
          </div>
        }
        onSubmit={confirmDelete}
        loading={isDeleteLoading}
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        title='Delete Staff'
        description={`Are you sure you want to delete this staff {${selected?.username}}. This action cannot be undone once performed?`}
      />
    </>
  );
};
