import { Link } from 'react-router-dom';

export const ForgotPasswordPage = () => {
  return (
    <div className='w-4/5 max-h-[80%] bg-white rounded-3xl p-8 shadow-lg'>
      <h2 className='text-3xl font-bold text-primary-500 uppercase'>
        Forgot Password
      </h2>
      <h4 className='text-sm text-gray-400'>
        Lost/Forgot your password, no problem?.
      </h4>
      <div className='my-8'>
        <h2 className='text-2xl font-semibold text-center'>
          Contact your Branch/Store Administrator for a password reset.
        </h2>
      </div>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2 text-xs mt-5'>
          <p className='text-gray-400'>Remembered Password?.</p>
          <Link className='text-primary-500 hover:text-primary-600' to='/auth/login'>
            Login.
          </Link>
        </div>
        <div className='flex items-center gap-2 text-xs mt-5'>
          <p className='text-gray-400'>Unable to recover your account?.</p>
          <Link className='text-primary-500 hover:text-primary-600' to='/contact-us'>
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};
