import { BaseInput } from '@lib/Input';
import { IRegisterDTO } from '@network/interface';
import { FormikErrors } from 'formik';

interface IProps {
  values: IRegisterDTO;
  handleChange: any;
  errors: FormikErrors<IRegisterDTO>;
}
export const AccountInformationStep = ({
  errors,
  handleChange,
  values,
}: IProps) => {
  return (
    <div>
      <div className='flex justify-between gap-3'>
        <BaseInput
          label='First Name'
          name='firstname'
          placeholder='Enter your first name'
          type='text'
          required
          onChange={handleChange}
          value={values.firstname}
          error={errors.firstname}
        />
        <BaseInput
          label='Middle Name'
          name='middlename'
          placeholder='Enter your middle name'
          type='text'
          optional
          onChange={handleChange}
          value={values.middlename}
          error={errors.middlename}
        />
      </div>
      <div className='flex justify-between gap-3'>
        <BaseInput
          label='Last Name'
          name='lastname'
          placeholder='Enter your last name'
          type='text'
          required
          onChange={handleChange}
          value={values.lastname}
          error={errors.lastname}
        />
        <BaseInput
          label='Phone Number'
          name='phone'
          placeholder='Enter your first name'
          type='text'
          required
          onChange={handleChange}
          value={values.phone}
          error={errors.phone}
        />
      </div>

      <div className='flex justify-between gap-3'>
        <BaseInput
          label='Email'
          name='email'
          placeholder='Enter your email'
          type='email'
          onChange={handleChange}
          value={values.email}
          error={errors.email}
        />
        <BaseInput
          label='Password'
          name='password'
          placeholder='Enter your password'
          type='password'
          onChange={handleChange}
          value={values.password}
          error={errors.password}
        />
      </div>
    </div>
  );
};
