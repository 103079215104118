import { useHttpPrivate } from '@network/api';
import {
  BaseError,
  Endpoints,
  ICreateStaffDto,
  ICreateStaffReponse,
  IGetSingleStaff,
  IGetStaffesPaginated,
  PaginationData,
  QueryKeys,
  Staff,
} from '@network/interface';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import * as yup from 'yup';
import { useGetAllBranches } from './branch.network';
import { useGetCountriesAndStates } from './country.network';
import { useGetAllRoles } from './role.network';

export const useGetStaffsPaginated = () => {
  const httpPrivate = useHttpPrivate();
  const [paginator, setPaginator] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const [staffs, setStaffs] = useState<Staff[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationData | null>(
    null
  );

  const { isLoading } = useQuery(
    [QueryKeys.GET_STAFF_PAGINATED],
    async () => {
      const { data } = await httpPrivate.get<IGetStaffesPaginated>(
        `${Endpoints.GET_STAFF_PAGINATED}?page=${paginator.currentPage}&limit=${paginator.pageSize}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setStaffs(data.data);
        setPaginationData(data?.pagination);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const updatePage = (page?: number, pageSize?: number) => {
    setPaginator((prev) => ({
      currentPage: page || prev.currentPage,
      pageSize: pageSize || prev.pageSize,
    }));
  };

  return { isLoading, staffs, paginationData, paginator, updatePage };
};

export const useCreateStaff = ({
  prev,
  handleCloseAddStaffModal,
}: {
  prev?: Staff | null;
  handleCloseAddStaffModal: () => void;
}) => {
  const httpPrivate = useHttpPrivate();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Please provide a valid email address!.')
      .required('Email address is required!.'),
    address: yup.string().required('Address is required!.'),
    lga: yup.string().required('LGA is required!.'),
    state: yup.string().required('State is required!.'),
    country: yup.string().required('Country is required!.'),
    firstname: yup.string().required('First Name is required!.'),
    lastname: yup.string().required('Last Name is required!.'),
    middlename: yup.string(),
    phone: yup.string().required('Phone number is required!.'),
    gender: yup.string().required('Gender is required!.'),
    branch: yup.string().required('Branch is required!.'),
    status: yup.string().required(),
    role: yup.string().required(),
  });

  const initialValues: ICreateStaffDto = {
    address: prev ? prev?.address : '',
    country: prev ? prev?.country : '',
    email: prev ? prev?.email : '',
    firstname: prev ? prev?.firstname : '',
    gender: prev ? prev?.gender : '',
    lastname: prev ? prev?.lastname : '',
    lga: prev ? prev?.lga : '',
    middlename: prev ? prev?.middlename : '',
    phone: prev ? prev?.phone : '',
    state: prev ? prev?.state : '',
    passport: prev ? prev?.passport : '',
    branch: prev ? prev?._branch?._branch_id : '',
    role: prev ? prev?.role : '',
    status: prev ? prev?.status : '',
  };

  useEffect(() => {
    if (prev) {
      setFieldValue('address', prev?.address || '');
      setFieldValue('country', prev?.country || '');
      setFieldValue('email', prev?.email || '');
      setFieldValue('firstname', prev?.firstname || '');
      setFieldValue('gender', prev?.gender || '');
      setFieldValue('lastname', prev?.lastname || '');
      setFieldValue('lga', prev?.lga || '');
      setFieldValue('middlename', prev?.middlename || '');
      setFieldValue('phone', prev?.phone || '');
      setFieldValue('state', prev?.state || '');
      setFieldValue('passport', prev?.passport || '');
      setFieldValue('branch', prev?._branch?._branch_id || '');
      setFieldValue('role', prev?.role || '');
      setFieldValue('status', prev?.status || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev]);

  // const { isLoading: isLoadingSingleStaff, staff } = useGetSingleStaffById({
  //   id: prev ?? '',
  // });

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    async (values: ICreateStaffDto) => {
      const res = await httpPrivate.post<ICreateStaffReponse>(
        Endpoints.CREATE_STAFF,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries([QueryKeys.GET_STAFF_PAGINATED]);
        handleCloseAddStaffModal();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { isLoading: isEditLoading, mutate: update } = useMutation(
    async (values: ICreateStaffDto) => {
      const res = await httpPrivate.put<ICreateStaffReponse>(
        `${Endpoints.EDIT_STAFF}/${prev?._account_id}`,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries([QueryKeys.GET_STAFF_PAGINATED]);
        handleCloseAddStaffModal();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = (payload: ICreateStaffDto) => {
    console.log(payload);
    mutate(payload);
  };

  const onEditSubmit = (payload: ICreateStaffDto) => {
    console.log(payload);
    update(payload);
  };

  const {
    errors,
    handleSubmit,
    handleChange,
    values,
    dirty,
    isValid,
    setFieldValue,
  } = useFormik({
    onSubmit: prev ? onEditSubmit : onSubmit,
    initialValues,
    validationSchema: schema,
  });

  const { cities, countries, states } = useGetCountriesAndStates({
    country: values.country,
    state: values.state,
  });

  const { isLoading: isLoadingBranches, branches } = useGetAllBranches();
  const { isLoading: isLoadingRoles, roles } = useGetAllRoles();

  const isDisabled = !dirty || !isValid;

  const isEdit = !!prev;

  const stateOptions =
    states?.map((state) => ({ key: state, value: state })) ?? [];

  const statusOptions = [
    { key: 'ACTIVE', value: 'ACTIVE' },
    { key: 'INACTIVE', value: 'INACTIVE' },
    { key: 'LOCK', value: 'LOCKED' },
    { key: 'DISABLE', value: 'DISABLED' },
    { key: 'BAN', value: 'BANNED' },
  ];

  const countryOptions =
    countries?.map((country) => ({ key: country, value: country })) ?? [];

  const citiesOptions =
    cities?.map((city) => ({ key: city, value: city })) ?? [];

  const branchesOptions =
    branches?.map((branch) => ({
      key: branch.name,
      value: branch._branch_id,
    })) ?? [];

  const rolesOptions =
    roles?.map((role) => ({
      key: role.name,
      value: role._role_id,
    })) ?? [];

  const genderOptions = [
    { key: 'Male', value: 'MALE' },
    { key: 'Female', value: 'FEMALE' },
    { key: 'Prefer not to say', value: 'PREFER_NOT_TO_SAY' },
  ];

  return {
    isLoading: isLoading || isEditLoading,
    handleChange,
    errors,
    isDisabled,
    values,
    isEdit,
    handleSubmit,
    countryOptions,
    stateOptions,
    statusOptions,
    citiesOptions,
    branchesOptions,
    isLoadingBranches,
    isLoadingRoles,
    genderOptions,
    rolesOptions,
  };
};

export const useGetSingleStaffById = ({ id = '' }) => {
  const httpPrivate = useHttpPrivate();

  const [staff, setStaff] = useState<Staff | null>(null);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_STAFFS, { staff }],
    async () => {
      const { data } = await httpPrivate.get<IGetSingleStaff>(
        `${Endpoints.GET_SINGLE_STAFF}?id=${id}`
      );
      return data;
    },
    {
      enabled: !id ? false : true,
      onSuccess: (data) => {
        setStaff(data.data);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  return { isLoading, staff };
};

export const useDeleteStaff = ({ callback }: { callback?: () => void }) => {
  const httpPrivate = useHttpPrivate();

  const { isLoading, mutate } = useMutation(
    async (id: string) => {
      const { data } = await httpPrivate.delete<IGetSingleStaff>(
        `${Endpoints.DELETE_STAFF}/${id}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        callback?.();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleDelete = (id: string) => {
    mutate(id);
  };

  return { isLoading, handleDelete };
};
