import { PageHeader } from '@components/PageHeader';
import { SuppliersTable } from './tables';

export const SuppliersManagementPage = () => {
  return (
    <div>
      <PageHeader title='Suppliers Management' />
      <SuppliersTable />
    </div>
  );
};
