import { MOMENT_FORMAT } from '@app/helpers';
import ConfirmationModal from '@components/ConfirmDeleteModal';
import { useDisclosure } from '@hooks/useDisclosure';
import { Button } from '@lib/Button';
import { Table, TableActionMenu } from '@lib/Table';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { useDeleteCustomer, useGetCustomersPaginated } from '@network/hooks';
import { Customer } from '@network/interface';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { CreateCustomerModal } from '../modals';

export const ManageCustomersTable = () => {
  const {
    isOpen: isOpenAddCustomerModal,
    onClose: onCloseAddCustomerModal,
    onOpen: onOpenAddCustomerModal,
  } = useDisclosure();

  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();

  const { handleDelete, isLoading: isDeleteLoading } = useDeleteCustomer({
    callback: () => onDeleteClose(),
  });

  const [selected, setSelected] = useState<Customer | null>(null);

  const handleEditClick = (id: Customer) => {
    setSelected(id);
    onOpenAddCustomerModal();
  };

  const handleDeleteClick = (id: Customer) => {
    setSelected(id);
    onDeleteOpen();
  };

  const confirmDelete = () => {
    handleDelete(selected?._customer_id!);
  };

  const { isLoading, paginationData, customers, updatePage } =
    useGetCustomersPaginated();

  const columns = useMemo<ColumnDef<Customer>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: '_customer_id',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Customer Name',
        accessorKey: 'name',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Added By',
        accessorKey: '_createdBy',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: (info) => (
          <span
            className={`px-3 py-1 text-xs rounded-md text-white ${
              info.getValue() === 'ACTIVE'
                ? 'bg-green-500'
                : info.getValue() === 'INACTIVE'
                ? 'bg-red-500'
                : ''
            }`}>
            {info.getValue() as string}
          </span>
        ),
      },
      {
        header: 'Date Added',
        accessorKey: 'createdAt',
        cell: (info) => moment(info.getValue() as string).format(MOMENT_FORMAT),
      },
      {
        header: 'Last Updated',
        accessorKey: 'updatedAt',
        cell: (info) => moment(info.getValue() as string).format(MOMENT_FORMAT),
      },
      {
        header: 'Action',
        accessorFn: (row) => row,
        cell: (info) => {
          return (
            <TableActionMenu
              actions={['DELETE', 'EDIT']}
              actionHandler={{
                handleEdit: handleEditClick,
                handleDelete: handleDeleteClick,
              }}
              value={info.cell.getValue() as string}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //   const { branches, isLoading, paginationData, updatePage } =
  //     useGetBranchPaginated();

  return (
    <>
      {/* <div>
        <BaseSelect
          options={[10, 20, 30, 40, 50, 100].map((val) => ({
            key: val.toString(),
            value: val.toString(),
          }))}
        />
      </div> */}
      <div className='w-full bg-white flex items-center justify-end gap-5 my-5 rounded-md border border-slate-200 p-4 dark:border-slate-300'>
        <div className='w-[15%]'>
          <Button label='Add Customer' onClick={onOpenAddCustomerModal} />
        </div>
      </div>
      <Table
        columns={columns}
        data={customers}
        totalPageCount={paginationData?.total_pages}
        updatePage={updatePage}
        isLoading={isLoading}
      />

      <CreateCustomerModal
        isOpen={isOpenAddCustomerModal}
        onClose={onCloseAddCustomerModal}
        prev={selected}
      />

      <ConfirmationModal
        icon={
          <div className='w-20 h-20 rounded-full bg-slate-200 flex items-center justify-center'>
            <DeleteForeverOutlined color='error' fontSize='large' />
          </div>
        }
        onSubmit={confirmDelete}
        loading={isDeleteLoading}
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        title='Delete Customer'
        description={`Are you sure you want to delete this customer {${selected?.name}}. This action cannot be undone once performed?`}
      />
    </>
  );
};
