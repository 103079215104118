export enum QueryKeys {
  GET_BRANCHES_PAGINATED = 'GET_BRANCHES_PAGINATED',
  GET_ALL_BRANCHES = 'GET_ALL_BRANCHES',
  GET_ROLES_PAGINATED = 'GET_ROLES_PAGINATED',
  GET_STAFF_PAGINATED = 'GET_STAFF_PAGINATED',
  GET_PRODUCTS_PAGINATED = 'GET_PRODUCTS_PAGINATED',
  GET_ALL_ROLES = 'GET_ALL_ROLES',
  GET_ALL_STAFFS = 'GET_ALL_STAFFS',
  GET_ALL_BUSINESS = 'GET_ALL_BUSINESS',
  GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS',
  GET_ALL_PRODUCT_CATEGORIES = 'GET_ALL_PRODUCT_CATEGORIES',
  GET_ALL_BRANCH_PRODUCTS = 'GET_ALL_BRANCH_PRODUCTS',
  GET_ALL_PACKAGES = 'GET_ALL_PACKAGES',
  GET_SUPPLIERS_PAGINATED = 'GET_SUPPLIERS_PAGINATED',
  GET_ALL_SUPPLIERS = 'GET_ALL_SUPPLIERS',
  GET_ALL_PRODUCT_CATEGORIES_PAGINATED = 'GET_ALL_PRODUCT_CATEGORIES_PAGINATED',
  GET_ALL_CUSTOMERS_PAGINATED = 'GET_ALL_CUSTOMERS_PAGINATED',
  GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS',
  GET_ALL_EXPENSES_PAGINATED = 'GET_ALL_EXPENSES_PAGINATED',
  GET_ALL_EXPENSES = 'GET_ALL_EXPENSES',
  GET_ALL_SALES_PAGINATED = 'GET_ALL_SALES_PAGINATED',
  GET_ALL_SALES = 'GET_ALL_SALES',
}
