export const SuccessPage = () => {
  return (
    <div className='w-4/5 max-h-[80%] bg-white rounded-3xl p-8 shadow-lg'>
      <h2 className='text-3xl font-bold text-primary-500 uppercase text-center'>
        Account Created Successfully
      </h2>
      <div className='my-8'>
        <h2 className='text-2xl font-bold text-center'>Congratulation,</h2>
        <h3 className='text-xl text-center'>
          Please Check Your Email for Your Verification Link
        </h3>
      </div>
    </div>
  );
};
