import { BaseInput } from '@lib/Input';
import { BaseSelect } from '@lib/Select';
import { IRegisterDTO } from '@network/interface';
import { FormikErrors } from 'formik';

interface IProps {
  values: IRegisterDTO;
  handleChange: any;
  errors: FormikErrors<IRegisterDTO>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  countryOptions: { key: string; value: string }[];
  cityOptions: { key: string; value: string }[];
  stateOptions: { key: string; value: string }[];
}

export const PersonalInformationStep = ({
  errors,
  handleChange,
  values,
  setFieldValue,
  cityOptions,
  countryOptions,
  stateOptions,
}: IProps) => {
  const options = [
    { label: 'Male', value: 'MALE' },
    { label: 'Female', value: 'FEMALE' },
    { label: 'Prefer not to say', value: 'PREFER_NOT_TO_SAY' },
  ];
  return (
    <div>
      <div className='flex justify-between gap-3'>
        <BaseInput
          label='Phone Number'
          name='phone'
          placeholder='Enter your phone number'
          type='text'
          onChange={handleChange}
          value={values.phone}
          error={errors.phone}
        />
        <BaseSelect
          label='Country'
          placeholder='Select Country'
          options={countryOptions}
          name='country'
          type='country'
          onChange={handleChange}
          value={values.country}
          error={errors.country}
          disabled={!countryOptions || countryOptions.length < 0}
        />
      </div>
      <div>
        <div>
          <p className='text-sm text-gray-400 mb-2'>Gender</p>
        </div>
        <div className='flex gap-4'>
          {options.map((opt, _idx) => (
            <div
              className='inline-flex relative'
              key={_idx}
              onClick={() => setFieldValue('gender', opt.value)}>
              <input
                className='hidden'
                type='radio'
                id={opt.label}
                value={opt.value}
                name='gender'
                checked={values.gender === opt.value}
              />
              <label
                className={`border border-gray-400 hover:border-gray-600 text-gray-400 font-semibold transition duration-200 py-2 px-4 cursor-pointer rounded-lg text-sm ${
                  values.gender === opt.value
                    ? 'border-primary-500 hover:border-primary-500 text-primary-500'
                    : ''
                }`}
                htmlFor={opt.label}>
                {opt.label}
              </label>
              {values.gender === opt.value ? (
                <div
                  className={`absolute top-1 right-1 w-2 h-2 transition duration-200 rounded-full ${
                    values.gender === opt.value
                      ? 'bg-primary-500'
                      : 'bg-gray-300'
                  }`}
                />
              ) : null}
            </div>
          ))}
        </div>
        {errors?.gender ? (
          <small className={`ml-1 text-red-500 text-xs`}>
            {errors?.gender}
          </small>
        ) : null}
      </div>

      <div className='flex justify-between gap-3'>
        <BaseSelect
          label='Store State'
          placeholder='Select Store State'
          options={stateOptions}
          name='state'
          type='state'
          onChange={handleChange}
          value={values.state}
          error={errors.state}
          disabled={!values.country || !stateOptions || stateOptions.length < 0}
        />
        <BaseSelect
          label='Store LGA'
          placeholder='Select Store LGA'
          options={cityOptions}
          name='lga'
          type='lga'
          onChange={handleChange}
          value={values.lga}
          error={errors.lga}
          disabled={!values.state || !cityOptions || cityOptions.length < 0}
        />
      </div>
    </div>
  );
};
