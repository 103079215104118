import React from 'react';

interface IProps extends React.HTMLProps<HTMLInputElement> {
  error?: string;
  optional?: boolean;
}

export const BaseInput = ({ name, label, ...props }: IProps) => {
  return (
    <div className='flex-col my-2 w-full'>
      {label ? (
        <div className='mb-0.5'>
          <label
            htmlFor={name}
            className={`text-sm text-gray-400 flex items-center ${
              props.error ? 'text-red-500' : ''
            } `}>
            {label}
            {props.required ? (
              <span className='ml-2 text-red-500 text-sm'>*</span>
            ) : props.optional ? (
              <span className='ml-2 text-gray-300 text-xs italic'>
                (optional)
              </span>
            ) : null}
          </label>
        </div>
      ) : null}
      <input
        name={name}
        {...props}
        className={`w-full text-sm border border-gray-400 rounded-lg p-[0.7rem] focus:ring-0 bg-transparent ${
          props.className
        }  ${props.error ? 'border-red-500' : ''} `}
      />
      {props.error ? (
        <span className={`ml-1 text-red-500 text-xs`}>{props.error}</span>
      ) : null}
    </div>
  );
};
