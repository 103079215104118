import { ChevronLeft, ChevronRight } from '@assets';
import { DOTS, usePagination } from '@hooks/usePagination';

interface IProps {
  currentPage: number;
  siblingCount?: number;
  totalPageCount: number;
  pageSize: number;
  paginator: {
    canNextPage: boolean;
    canPrevPage: boolean;
    nextPage: () => void;
    prevPage: () => void;
    setPageIndex: (index: number) => void;
  };
}

export const Pagination = ({
  currentPage,
  siblingCount = 1,
  totalPageCount,
  pageSize,
  paginator,
}: IProps) => {
  const paginationRange = usePagination({
    currentPage,
    siblingCount,
    totalPageCount,
  });

  return (
    <div className='flex items-center justify-between border-t border-slate-200 dark:border-slate-300 dark:bg-slate-700 bg-white px-4 py-3 sm:px-6 md:py-8'>
      <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
        <p>
          showing page {currentPage} of {totalPageCount}
        </p>
        <div>
          <nav
            className='isolate inline-flex -space-x-px rounded-md shadow-sm overflow-hidden border border-slate-100'
            aria-label='Pagination'>
            <p
              className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-dark-300 hover:bg-primary-500 hover:text-white transition duration-500 focus:z-20 focus:outline-offset-0 ${
                paginator.canPrevPage
                  ? 'cursor-pointer opacity-100'
                  : 'cursor-not-allowed opacity-30'
              }`}
              onClick={() =>
                paginator.canPrevPage ? paginator.prevPage() : null
              }>
              <span className='sr-only'>Previous</span>
              <ChevronLeft className='fill-dark-500' />
            </p>
            {paginationRange?.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                return (
                  <span
                    key={index}
                    className='relative inline-flex items-center px-4 py-2 text-sm font-semibold text-dark-500 cursor-pointer focus:outline-offset-0'>
                    ...
                  </span>
                );
              }
              if (Number(pageNumber) === currentPage) {
                return (
                  <div
                    key={index}
                    aria-current='page'
                    className='relative z-10 inline-flex items-center bg-primary-500 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
                    {pageNumber}
                  </div>
                );
              }

              return (
                <p
                  aria-current='page'
                  className='relative z-10 text-dark-300 inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  onClick={() => paginator.setPageIndex(Number(pageNumber))}>
                  {pageNumber}
                </p>
              );
            })}
            <p
              className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-dark-300 hover:bg-primary-500 hover:text-white transition duration-500 focus:z-20 focus:outline-offset-0 ${
                paginator.canNextPage
                  ? 'cursor-pointer opacity-100'
                  : 'cursor-not-allowed opacity-30'
              }`}
              onClick={() =>
                paginator.canNextPage ? paginator.nextPage() : null
              }>
              <span className='sr-only'>Next</span>
              <ChevronRight className='fill-dark-500' />
            </p>
          </nav>
        </div>
      </div>
    </div>
  );
};
