import { PageHeader } from '@components/PageHeader';
import { CategoryTable } from './tables';

export const CategoryManagementPage = () => {
  return (
    <div>
      <PageHeader title='Product Category Management' />
      <CategoryTable />
    </div>
  );
};
