import { CubeLogo } from '@assets';
import { SidebarHeader } from '@components/Sidebar';
import Sidebar from '@components/Sidebar/Sidebar';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

export const HomeLayout = () => {
  return <div>HomeLayout</div>;
};

export const AuthLayout = () => {
  return (
    <>
      <div className='w-full absolute top-0 left-0'>
        <nav className='w-4/5 m-auto py-8'>
          <img src={CubeLogo} alt='Cube 700' className='w-20' />
        </nav>
      </div>
      <div className='h-full w-full flex justify-between items-center'>
        <div className='w-1/2 m-auto'>
          <div className='w-3/5 mx-auto'>
            <h2 className='text-6xl font-bold text-gray-800'>
              Keep tracks of activities in your business.
            </h2>
            {/* REASONS TO USE */}
            <div className='my-10 flex-col flex gap-3'>
              <div className='flex items-center gap-3 '>
                <div className='bg-primary-500 w-5 h-5 flex items-center justify-center rounded-full'>
                  <span className='text-white font-bold text-xs'>&#10003;</span>
                </div>
                <p className='text-sm text-gray-500'>
                  Create cards that work exactly as you configured them
                </p>
              </div>
              <div className='flex items-center gap-3'>
                <div className='bg-primary-500 w-5 h-5 flex items-center justify-center rounded-full'>
                  <span className='text-white font-bold text-xs'>&#10003;</span>
                </div>
                <p className='text-sm text-gray-500'>
                  Focus critical resources on your core business
                </p>
              </div>
              <div className='flex items-center gap-3'>
                <div className='bg-primary-500 w-5 h-5 flex items-center justify-center rounded-full'>
                  <span className='text-white font-bold text-xs'>&#10003;</span>
                </div>
                <p className='text-sm text-gray-500'>
                  Make real-time decisions on charges and spendings.
                </p>
              </div>
            </div>
            {/* TRUSTED BY */}
            <div>
              <p className='text-xs text-gray-400'>TRUSTED BY THE BEST</p>
            </div>
            {/* TRUSTED USERS */}
          </div>
        </div>
        <div className='w-1/2 bg-primary-500 h-[95vh] bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-primary-400 via-primary-500 to-gray-700 rounded-bl-[5rem] flex items-center justify-center'>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export const AdminLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className='bg-gray-100 dark:bg-slate-700'>
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className='flex h-screen overflow-hidden relative'>
        {/* <!-- ===== Sidebar Start ===== --> */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className='relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden'>
          {/* <!-- ===== Header Start ===== --> */}
          <SidebarHeader
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
          {/* <!-- ===== Header End ===== --> */}
          {/* <!-- ===== Main Content Start ===== --> */}
          <main>
            <div className='mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10'>
              <Outlet />
            </div>
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
  );
};
