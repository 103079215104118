import { logoutUser } from '@app/index';
import { ChevronArrowDown, LogoutIcon } from '@assets';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const user = 'https://picsum.photos/200/300';

export const DropdownUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { username } = useAppSelector((state) => state.user);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/');
  };

  return (
    <div className='relative'>
      <Link
        ref={trigger}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className='flex items-center gap-4'
        to='#'>
        <span className='hidden text-right lg:block'>
          <span className='block text-sm font-medium text-black dark:text-slate-200'>
            {username}
          </span>
          <span className='block text-[0.65rem] text-slate-400 italic dark:text-slate-400'>
            (Super Administrator)
          </span>
        </span>

        <span className='h-12 w-12 rounded-full overflow-hidden'>
          <img src={user} alt='User' className='w-full h-full' />
        </span>

        <ChevronArrowDown
          className={`hidden fill-black dark:fill-slate-400 sm:block transition duration-500 ease-in-out ${
            dropdownOpen ? 'rotate-180' : ''
          }`}
        />
      </Link>

      {/* <!-- Dropdown Start --> */}
      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={`absolute right-0 mt-[1.2rem] flex w-64 flex-col rounded-sm border border-slate-300 bg-white shadow-2xl dark:bg-slate-800 dark:border-slate-700 ${
          dropdownOpen === true ? 'block' : 'hidden'
        }`}>
        {/* <ul className='flex flex-col gap-5 border-b border-slate-300 dark:border-slate-700 px-6 py-5'>
          <li>
            <Link
              to='/store/profile'
              className='flex items-center gap-3.5 text-sm font-medium duration-300 dark:text-white ease-in-out hover:text-primary-500 lg:text-base'>
              <UserIcon className='fill-primary-500' />
              My Profile
            </Link>
          </li>
          <li>
            <Link
              to='/store/settings'
              className='flex items-center gap-3.5 text-sm font-medium duration-300 dark:text-white ease-in-out hover:text-primary-500 lg:text-base'>
              <SettingsIcon className='fill-primary-500' />
              Account Settings
            </Link>
          </li>
        </ul> */}
        <button
          onClick={handleLogout}
          className='flex items-center gap-3.5 py-4 px-6 text-sm font-medium duration-300 ease-in-out text-red-400 hover:text-red-600 lg:text-base'>
          <LogoutIcon className='fill-red-600' />
          Log Out
        </button>
      </div>
      {/* <!-- Dropdown End --> */}
    </div>
  );
};
