export enum Endpoints {
  // AUTH

  LOGIN = '/auth/login',
  REGISTER = '/auth/create-account',
  VERIFY_ACCOUNT = '/auth/verify-account',

  DASHBOARD_DETAILS = '/store/analytics',
  // BRANCHES

  GET_BRANCHES_PAGINATED = '/branch/all/paginated',
  GET_ALL_BRANCHES = '/branch/all',
  CREATE_BRANCH = '/branch/create',
  GET_SINGLE_BRANCH = '/branch',
  EDIT_BRANCH = '/branch/update',
  DELETE_BRANCH = '/branch/delete',

  // STAFFS

  GET_STAFF_PAGINATED = '/staff/all/paginated',
  GET_SINGLE_STAFF = '/staff',
  CREATE_STAFF = '/staff/create',
  EDIT_STAFF = '/staff/update',
  DELETE_STAFF = '/staff/delete',

  // ROLES
  GET_ROLES_PAGINATED = '/role/all/paginated',
  GET_SINGLE_ROLE = '/role',
  GET_ALL_ROLES = '/role/all',

  // REPORTS

  // PRODUCTS
  GET_PRODUCTS_PAGINATED = '/product/all/paginated',
  GET_ALL_PRODUCTS = '/product/all/active',
  CREATE_PRODUCT = '/product/create',
  EDIT_PRODUCT = '/product/update',

  // PRODUCTS - CATEGORY
  GET_PRODUCTS_CATEGORIES_PAGINATED = '/category/all/paginated',
  GET_ALL_PRODUCTS_CATEGORIES = '/category/all',
  CREATE_PRODUCT_CATEGORY = '/category/create',
  GET_SINGLE_PRODUCT_CATEGORY = '/category',
  EDIT_PRODUCT_CATEGORY = '/category/update',
  DELETE_PRODUCT_CATEGORY = '/category/delete',

  // INVENTORY

  // SALES
  GET_ALL_SALES_PAGINATED = '/sales/all/paginated',
  GET_ALL_SALES = '/sales/all',
  CREATE_SALES = '/sales/create',

  // CUSTOMERS
  GET_ALL_CUSTOMERS_PAGINATED = '/customer/all/paginated',
  GET_ALL_CUSTOMERS = '/customer/all/active',
  CREATE_CUSTOMER = '/customer/create',
  GET_SINGLE_CUSTOMER = '/customer',
  EDIT_CUSTOMER = '/customer/update',
  DELETE_CUSTOMER = '/customer/delete',

  // PACKAGES
  GET_ALL_PRODUCT_CATEGORIES = '/category/all/active',

  // EXPENSES
  GET_ALL_EXPENSES_PAGINATED = '/expense/all/paginated',
  GET_ALL_EXPENSES = '/expense/all/active',
  CREATE_EXPENSES = '/expense/create',
  EDIT_EXPENSE = '/expense/update',

  // SUPPY
  GET_SUPPLIERS_PAGINATED = '/supplier/all/paginated',
  DELETE_SUPPLER = '/supplier/delete',
  GET_ALL_SUPPLIERS = '/supplier/all/active',
  CREATE_SUPPLIER = '/supplier/create',
  GET_SINGLE_SUPPLIER = '/supplier',
  EDIT_SUPPLIER = '/supplier/update',

  // BUSINESS
  GET_ALL_BUSINESS = '/business/all',

  // PACKAGES
  GET_ALL_PACKAGES = '/package/all/active',
}
