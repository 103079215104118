import { VerticalDotsIcon } from '@assets';
import { useEffect, useRef, useState } from 'react';

interface IProps {
  actions: Array<'EDIT' | 'DELETE' | 'VIEW' | 'MOVE'>;
  actionHandler?: {
    handleEdit?: (id: any) => void;
    handleView?: (id: any) => void;
    handleDelete?: (id: any) => void;
    handleMove?: (id: any) => void;
  };
  value?: string;
}

export const TableActionMenu = ({ actions, actionHandler, value }: IProps) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current) return;
      if (
        !showDropdown ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setShowDropdown(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!showDropdown || keyCode !== 27) return;
      setShowDropdown(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className='relative'>
      <button
        ref={trigger}
        onClick={() => setShowDropdown((prev) => !prev)}
        className='bg-slate-100 hover:bg-slate-200 font-medium rounded-lg text-xs px-2 py-2 text-center inline-flex items-center dark:bg-slate-600 dark:hover:bg-slate-700'
        type='button'>
        <VerticalDotsIcon />
      </button>
      <div
        ref={dropdown}
        onFocus={() => setShowDropdown(true)}
        onBlur={() => setShowDropdown(false)}
        className={`absolute top-0 mt-5 right-0 z-[99999] bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 dark:bg-gray-700 hidden ${
          showDropdown ? '!block' : ''
        }`}>
        <ul
          className='py-2 text-sm text-gray-700 dark:text-gray-200'
          aria-labelledby='dropdownDefaultButton'>
          {actions.includes('VIEW') ? (
            <li onClick={() => actionHandler?.handleMove?.(value ?? '')}>
              <p className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer'>
                View
              </p>
            </li>
          ) : null}
          {actions.includes('EDIT') ? (
            <li onClick={() => actionHandler?.handleEdit?.(value ?? '')}>
              <p className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer'>
                Edit
              </p>
            </li>
          ) : null}
          {actions.includes('DELETE') ? (
            <li onClick={() => actionHandler?.handleDelete?.(value ?? '')}>
              <p className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-red dark:hover:text-white cursor-pointer'>
                Delete
              </p>
            </li>
          ) : null}
          {actions.includes('MOVE') ? (
            <li onClick={() => actionHandler?.handleMove?.(value ?? '')}>
              <p className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer'>
                Move
              </p>
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
};
