import { PageHeader } from '@components/PageHeader';
import { useAppSelector } from '@hooks/useAppSelector';

export const ProfilePage = () => {
  const userPic = 'https://picsum.photos/200/300';
  const { user } = useAppSelector((state) => state);
  return (
    <div>
      <PageHeader title='User Profile' />
      <section>
        <div className='flex items-center'>
          <div className='h-32 w-32 rounded-full overflow-hidden'>
            <img src={userPic} alt='User' className='w-full h-full' />
          </div>
          <div className='p-4'>
            <h3 className='font-bold text-2xl'>{user?.username}</h3>
            <span className='block text-md text-slate-400 italic dark:text-slate-400'>
              {user?.email}
            </span>
          </div>
        </div>
      </section>

      <section className='mt-12'>
        <h5 className='text-2xl'>User Details</h5>
      </section>
    </div>
  );
};
