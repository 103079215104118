import { Spinner } from '@lib/Loaders';
import { Pagination } from '@lib/Pagination';
import {
  ColumnDef,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

export const Table = ({
  columns,
  data,
  totalPageCount,
  pageSize: defaultPageSize,
  updatePage,
  isLoading,
}: {
  columns: ColumnDef<any>[];
  data: any[];
  totalPageCount?: number;
  pageSize?: number;
  updatePage?: (currentPage?: number, pageSize?: number) => void;
  isLoading?: boolean;
}) => {
  let isData = false;
  if (data.length <= 0) {
    isLoading = false;
  } else {
    isData = true;
  }
  // console.log(isLoading);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: defaultPageSize ?? 10,
  });

  useEffect(() => {
    if (updatePage) {
      updatePage(pageIndex + 1, pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  // Use the state and functions returned from useTable to build your UI
  const table = useReactTable({
    data: data ?? [],
    columns,
    pageCount: totalPageCount ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  return (
    <div className='flex flex-col border border-gray-200 rounded-md overflow-hidden shadow-lg'>
      <div className='align-middle inline-block min-w-full overflow-x-scroll'>
        {isLoading ? (
          <div className='w-full h-[20rem] flex items-center justify-center'>
            <Spinner color='primary' />
          </div>
        ) : !isLoading && !isData ? (
          <div className='min-w-full divide-y divide-gray-200 p-12 text-center bg-gray-10'>
            No Data Found
          </div>
        ) : (
          <table className='min-w-full divide-y divide-gray-200 overflow-x-scroll bg-primary-500'>
            <thead className='bg-gray-10 py-4'>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <td
                      colSpan={header.colSpan}
                      key={header.id}
                      className='px-6 py-3 whitespace-nowrap text-xs font-semibold text-white'>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody className='bg-white divide-y divide-gray-200 text-xs dark:bg-slate-800 dark:text-white '>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          className='px-6 py-3 whitespace-nowrap'
                          key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <Pagination
          currentPage={table.getState().pagination.pageIndex + 1}
          totalPageCount={table.getPageCount()}
          pageSize={table.getState().pagination.pageSize}
          paginator={{
            canNextPage: table.getCanNextPage(),
            canPrevPage: table.getCanPreviousPage(),
            nextPage: table.nextPage,
            prevPage: table.previousPage,
            setPageIndex: table.setPageIndex,
          }}
        />
      </div>
    </div>
  );
};
