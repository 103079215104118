import { useHttpPrivate } from '@network/api';
import {
  BaseError,
  Endpoints,
  ICreateProductDto,
  ICreateProductReponse,
  IGetProductsPaginated,
  PaginationData,
  Product,
  QueryKeys,
} from '@network/interface';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useGetAllBranches } from './branch.network';
import { useGetAllProductCategories } from './category.network';
import { useGetAllSuppliers } from './supplier.network';

export const useCreateProduct = ({
  prev,
  handleCloseModal,
}: {
  prev?: Product | null | undefined;
  handleCloseModal: () => void;
}) => {
  const httpPrivate = useHttpPrivate();
  const [selectedImages, setSelectedImages] = useState<FileList | null>(null);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    setSelectedImages(files);

    // Generate image previews
    const previewUrls: string[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const url = URL.createObjectURL(file);
      previewUrls.push(url);
    }
    setImagePreviews(previewUrls);
  };

  const schema = yup.object().shape({
    barcode: yup.number().required('This field is required!.'),
    branch: yup.string().required('This field is required!.'),
    category: yup.string().required('This field is required!.'),
    supplier: yup.string().required('This field is required!.'),
    name: yup.string().required('This field is required!.'),
    note: yup.string().notRequired(),
    price: yup.string().required('This field is required!.'),
    quantity: yup.string().required('This field is required!.'),
    status: yup.string().required('This field is required!.'),
  });

  const initialValues: ICreateProductDto = {
    barcode: prev ? prev?.barcode : '',
    branch: prev ? prev?._branch?._branch_id : '',
    category: prev ? prev?._category?._category_id : '',
    name: prev ? prev?.name : '',
    note: prev ? prev?.note : '',
    price: prev ? prev?.price.toString() : '',
    quantity: prev ? prev?.quantity.toString() : '',
    status: prev ? prev?.status : '',
    supplier: prev ? prev?._supplier?._supplier_id : '',
  };

  useEffect(() => {
    setFieldValue('barcode', prev?.barcode || '');
    setFieldValue('branch', prev?._branch?._branch_id || '');
    setFieldValue('category', prev?._category?._category_id || '');
    setFieldValue('name', prev?.name || '');
    setFieldValue('note', prev?.note || '');
    setFieldValue('price', prev?.price.toString() || '');
    setFieldValue('quantity', prev?.quantity.toString() || '');
    setFieldValue('status', prev?.status || '');
    setFieldValue('supplier', prev?._supplier?._supplier_id || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev]);

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    async (values: FormData) => {
      const res = await httpPrivate.post<ICreateProductReponse>(
        Endpoints.CREATE_PRODUCT,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries([QueryKeys.GET_PRODUCTS_PAGINATED]);
        handleCloseModal();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { isLoading: isEditLoading, mutate: update } = useMutation(
    async (values: FormData) => {
      const res = await httpPrivate.put<ICreateProductReponse>(
        `${Endpoints.EDIT_PRODUCT}/${prev?._product_id}`,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries([QueryKeys.GET_PRODUCTS_PAGINATED]);
        handleCloseModal();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = (payload: ICreateProductDto) => {
    const convertedPayload: ICreateProductDto = {
      ...payload,
      //@ts-ignore
      price: Number(payload.price),
      //@ts-ignore
      quantity: Number(payload.quantity),
    };

    if (!selectedImages || selectedImages.length === 0) {
      toast.error('Please select at least one image.');
      return;
    }

    const formData = new FormData();

    for (let i = 0; i < selectedImages.length; i++) {
      formData.append('images', selectedImages[i]);
    }

    // Append other form fields to the formData
    formData.append('barcode', convertedPayload.barcode);
    formData.append('branch', convertedPayload.branch);
    formData.append('category', convertedPayload.category);
    formData.append('name', convertedPayload.name);
    formData.append('note', convertedPayload.note);
    formData.append('price', convertedPayload.price);
    formData.append('quantity', convertedPayload.quantity);
    formData.append('status', convertedPayload.status);
    formData.append('supplier', convertedPayload.supplier);

    console.log(convertedPayload);
    console.log(formData);

    // Continue with the mutation and API call using formData
    mutate(formData);
  };
  const onUpdate = (payload: ICreateProductDto) => {
    const convertedPayload: ICreateProductDto = {
      ...payload,
      //@ts-ignore
      price: Number(payload.price),
      //@ts-ignore
      quantity: Number(payload.quantity),
    };

    if (!selectedImages || selectedImages.length === 0) {
      toast.error('Please select at least one image.');
      return;
    }

    const formData = new FormData();

    for (let i = 0; i < selectedImages.length; i++) {
      formData.append('images', selectedImages[i]);
    }

    // Append other form fields to the formData
    formData.append('barcode', convertedPayload.barcode);
    formData.append('branch', convertedPayload.branch);
    formData.append('category', convertedPayload.category);
    formData.append('name', convertedPayload.name);
    formData.append('note', convertedPayload.note);
    formData.append('price', convertedPayload.price);
    formData.append('quantity', convertedPayload.quantity);
    formData.append('status', convertedPayload.status);
    formData.append('supplier', convertedPayload.supplier);

    console.log(convertedPayload);
    console.log(formData);

    // Continue with the mutation and API call using formData
    update(formData);
  };

  const {
    errors,
    handleSubmit,
    handleChange,
    values,
    dirty,
    isValid,
    setFieldValue,
  } = useFormik({
    onSubmit: prev ? onUpdate : onSubmit,
    initialValues,
    validationSchema: schema,
  });

  const isDisabled = !dirty || !isValid;

  const isEdit = prev ? true : false;

  const statusOptions = [
    { key: 'ACTIVE', value: 'ACTIVE' },
    { key: 'INACTIVE', value: 'INACTIVE' },
  ];

  const { branches, isLoading: isLoadingBranches } = useGetAllBranches();
  const { categories, isLoading: isLoadingCategories } =
    useGetAllProductCategories();
  const { suppliers, isLoading: isLoadingSuppliers } = useGetAllSuppliers();

  const branchOptions = branches?.map((branch) => ({
    key: branch?.name,
    value: branch?._branch_id,
  }));
  const categoryOptions = categories?.map((cat) => ({
    key: cat?.name,
    value: cat?._category_id,
  }));
  const supplierOptions = suppliers?.map((sub) => ({
    key: `${sub?.firstname} ${sub?.lastname}`,
    value: sub?._supplier_id,
  }));

  return {
    isLoading: isLoading || isEditLoading,
    handleChange,
    errors,
    isDisabled,
    values,
    isEdit,
    handleSubmit,
    branchOptions,
    statusOptions,
    supplierOptions,
    isLoadingBranches,
    categoryOptions,
    isLoadingCategories,
    imagePreviews,
    handleImageChange,
    isLoadingSuppliers,
  };
};

export const useGetProductsPaginated = () => {
  const httpPrivate = useHttpPrivate();
  const [paginator, setPaginator] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const [products, setProducts] = useState<Product[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationData | null>(
    null
  );

  const { isLoading } = useQuery(
    [QueryKeys.GET_PRODUCTS_PAGINATED],
    async () => {
      const { data } = await httpPrivate.get<IGetProductsPaginated>(
        `${Endpoints.GET_PRODUCTS_PAGINATED}?page=${paginator.currentPage}&limit=${paginator.pageSize}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setProducts(data.data);
        setPaginationData(data?.pagination);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const updatePage = (page?: number, pageSize?: number) => {
    setPaginator((prev) => ({
      currentPage: page || prev.currentPage,
      pageSize: pageSize || prev.pageSize,
    }));
  };

  return { isLoading, products, paginationData, paginator, updatePage };
};

export const useGetAllProducts = () => {
  const httpPrivate = useHttpPrivate();

  const [products, setProducts] = useState<Product[]>([]);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_PRODUCTS],
    async () => {
      const { data } = await httpPrivate.get<IGetProductsPaginated>(
        `${Endpoints.GET_ALL_PRODUCTS}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        setProducts(data.data);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  return { isLoading, products };
};
