import { Button } from '@lib/Button';

export const StepperControl = ({
  handleClick,
  currentStep,
  steps,
  handleSubmit,
  isDisabled,
  isLoading,
}: {
  currentStep: number;
  handleClick: (direction: 'prev' | 'next') => void;
  steps: string[];
  handleSubmit?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}) => {
  return (
    <div className='container flex justify-between mt-4'>
      <div>
        <Button
          label='Back'
          className={`bg-gray-600 hover:bg-gray-700 ${
            currentStep === 1 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={() => handleClick('prev')}
        />
      </div>
      <div>
        <Button
          label={
            currentStep === steps.length + 1 - 1 ? 'Create Account' : 'Next'
          }
          onClick={() =>
            currentStep === steps.length + 1 - 1
              ? handleSubmit?.()
              : handleClick('next')
          }
          isLoading={isLoading}
          disabled={
            currentStep === steps.length + 1 - 1
              ? isDisabled || isLoading
              : false
          }
        />
      </div>
    </div>
  );
};
