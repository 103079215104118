import { PageHeader } from '@components/PageHeader';
import { SalesTable } from './tables';

export const SalesManagementPage = () => {
  return (
    <div>
      <PageHeader title='Sales Management' />
      <SalesTable />
    </div>
  );
};
