import { Button } from '@lib/Button';
import { BaseInput } from '@lib/Input';
import { Modal, ModalFooter } from '@lib/Modal';
import { BaseSelect } from '@lib/Select';
import { useCreateProductCategory } from '@network/hooks';
import { Category } from '@network/interface';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  prev?: Category | null;
}

export const CreateCategoryModal = ({ isOpen, onClose, prev }: IProps) => {
  const {
    errors,
    handleChange,
    handleSubmit,
    isDisabled,
    isEdit,
    isLoading,
    statusOptions,
    values,
  } = useCreateProductCategory({
    prev,
    handleCloseModal: onClose,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isEdit ? `Edit Category: ${prev}` : 'Add Category'}>
      <div className='p-4'>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Category Name'
            placeholder='Enter Category Name'
            name='name'
            type='name'
            onChange={handleChange}
            value={values.name}
            error={errors.name}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Category Description'
            placeholder='Enter Description'
            name='description'
            type='description'
            onChange={handleChange}
            value={values.description}
            error={errors.description}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseSelect
            label='Category Status'
            placeholder='Select Category Status'
            options={statusOptions}
            name='status'
            type='status'
            onChange={handleChange}
            value={values.status}
            error={errors.status}
          />
        </div>
      </div>

      <ModalFooter>
        <Button
          label={isEdit ? 'Save Category' : 'Create Category'}
          isLoading={isLoading}
          disabled={isDisabled}
          onClick={handleSubmit}
          type='submit'
        />
      </ModalFooter>
    </Modal>
  );
};
