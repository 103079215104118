import { useHttpPrivate } from '@network/api';
import {
  BaseError,
  Endpoints,
  ICreateSaleDto,
  ICreateSaleResponse,
  IGetAllSalesResponse,
  IGetSalesPaginatedResponse,
  PaginationData,
  QueryKeys,
  Sales,
} from '@network/interface';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import * as yup from 'yup';
import { useGetAllCustomers } from './customer.network';
import { useGetAllProducts } from './product.network';

export const useGetSalesPaginated = () => {
  const httpPrivate = useHttpPrivate();
  const [paginator, setPaginator] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const [sales, setSales] = useState<Sales[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationData | null>(
    null
  );

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_SALES_PAGINATED],
    async () => {
      const { data } = await httpPrivate.get<IGetSalesPaginatedResponse>(
        `${Endpoints.GET_ALL_SALES_PAGINATED}?page=${paginator.currentPage}&limit=${paginator.pageSize}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setSales(data.data);
        setPaginationData(data?.pagination);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const updatePage = (page?: number, pageSize?: number) => {
    setPaginator((prev) => ({
      currentPage: page || prev.currentPage,
      pageSize: pageSize || prev.pageSize,
    }));
  };

  return { isLoading, sales, paginationData, paginator, updatePage };
};

export const useGetAllSales = () => {
  const httpPrivate = useHttpPrivate();

  const [sales, setSales] = useState<Sales[]>([]);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_SALES],
    async () => {
      const { data } = await httpPrivate.get<IGetAllSalesResponse>(
        `${Endpoints.GET_ALL_SALES}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setSales(data.data);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  return { isLoading, sales };
};

export const useCreateSales = ({
  prev,
  handleCloseModal,
}: {
  prev?: Sales | null | undefined;
  handleCloseModal: () => void;
}) => {
  const httpPrivate = useHttpPrivate();

  const [items, setItems] = useState<
    { productId: string; piecesSold: number }[]
  >([]);
  const [selectedProduct, setSelectedProduct] = useState<{
    piecesSold: string;
    productId: string;
  } | null>(null);

  const handleItemChange = (e: any) => {
    const { name, value } = e.target;
    setSelectedProduct((prev) => ({ ...prev!, [name]: value }));
  };

  const handleAddItem = () => {
    if (selectedProduct) {
      const { productId, piecesSold } = selectedProduct;

      // Check if the product already exists in the items state
      const productExists = items.some((item) => item.productId === productId);

      if (productExists) {
        // If the product exists, update the piecesSold for that product
        setItems((prev) =>
          prev.map((item) =>
            item.productId === productId
              ? { ...item, piecesSold: item.piecesSold + parseInt(piecesSold) }
              : item
          )
        );
      } else {
        // If the product doesn't exist, add it to the items state
        setItems((prev) => [
          ...prev,
          { productId, piecesSold: parseInt(piecesSold) },
        ]);
      }

      setSelectedProduct(null);
    }
  };
  const handleRemoveItem = (productId: string) => {
    setItems((prevSelectedProducts) =>
      prevSelectedProducts.filter(
        (product) => product.productId.toString() !== productId.toString()
      )
    );
  };
  const schema = yup.object().shape({
    amount_paid: yup.string().required('This field is required!.'),
    customer_id: yup.string().required('This field is required!.'),
    note: yup.string().notRequired(),
    payment_method: yup.string().required('This field is required!.'),
  });

  const initialValues: ICreateSaleDto = {
    amount_paid: '',
    customer_id: '',
    payment_method: '',
    purchases: [],
    note: '',
  };

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    async (values: ICreateSaleDto) => {
      const res = await httpPrivate.post<ICreateSaleResponse>(
        Endpoints.CREATE_SALES,
        { ...values, purchases: JSON.stringify(items) }
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries([QueryKeys.GET_ALL_SALES_PAGINATED]);
        handleCloseModal();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const getAmount = (item: { productId: string; piecesSold: number }) => {
    const product = products.filter(
      (product) => product._product_id.toString() === item.productId.toString()
    )[0];

    if (product) {
      // Calculate the total amount by multiplying the price with piecesSold
      const totalAmount = product.price * item.piecesSold;
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN', // Replace with your desired currency code (e.g., 'EUR', 'GBP', 'INR', etc.)
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(totalAmount); // You can format the amount as per your requirement
    } else {
      return '--'; // Or any appropriate message for product not found
    }
  };

  const getTotalPrice = () => {
    const totalPrice = items.reduce((accumulator, item) => {
      const product = products.filter(
        (product) =>
          product._product_id.toString() === item.productId.toString()
      )[0];
      if (product) {
        return accumulator + product.price * item.piecesSold;
      } else {
        return accumulator;
      }
    }, 0);

    const formattedTotalPrice = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'NGN', // Replace with your desired currency code (e.g., 'EUR', 'GBP', 'INR', etc.)
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(totalPrice);

    return formattedTotalPrice;
  };

  useEffect(() => {
    setFieldValue(
      'amount_paid',
      items.reduce((accumulator, item) => {
        const product = products.filter(
          (product) =>
            product._product_id.toString() === item.productId.toString()
        )[0];
        if (product) {
          return accumulator + product.price * item.piecesSold;
        } else {
          return accumulator;
        }
      }, 0)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length]);

  const getProductName = (id: string) => {
    const prdName = products.filter(
      (prd) => prd._product_id.toString() === id.toString()
    )[0]?.name;

    return prdName;
  };

  const onSubmit = (payload: ICreateSaleDto) => {
    mutate(payload);
  };

  const { isLoading: isLoadingProducts, products } = useGetAllProducts();
  const { isLoading: isLoadingCustomers, customers } = useGetAllCustomers();

  const {
    errors,
    handleSubmit,
    handleChange,
    values,
    dirty,
    isValid,
    setFieldValue,
  } = useFormik({
    onSubmit,
    initialValues,
    validationSchema: schema,
  });

  const productOptions = products?.map((prod) => ({
    key: prod?.name,
    value: prod?._product_id,
  }));

  const customerOptions = customers?.map((cus) => ({
    key: cus?.name,
    value: cus?._customer_id,
  }));

  const paymentOptions = [
    {
      label: 'CASH',
      value: 'CASH',
    },
    {
      label: 'POS',
      value: 'POS',
    },
    {
      label: 'TRANSFER',
      value: 'TRANSFER',
    },
  ];

  const isDisabled = !dirty || !isValid;

  const isEdit = !!prev;

  console.log(values.amount_paid);

  return {
    isLoading,
    handleChange,
    errors,
    isDisabled,
    values,
    isEdit,
    setFieldValue,
    paymentOptions,
    handleCreate: handleSubmit,
    isLoadingProducts,
    handleEdit: () => {
      console.log('submit edit');
    },
    getProductName,
    items,
    handleAddItem,
    handleRemoveItem,
    handleItemChange,
    selectedProduct,
    productOptions,
    getAmount,
    getTotalPrice,
    isLoadingCustomers,
    customerOptions,
  };
};
