import { updateUser, updateUserEmail } from '@app/store';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { httpClient } from '@network/api';
import {
  Endpoints,
  ILoginDTO,
  ILoginResponse,
  IRegisterDTO,
  IRegisterResponse,
  Package,
  Routes,
} from '@network/interface';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { useGetAllBusinesses } from './business.network';
import { useGetCountriesAndStates } from './country.network';
import { useGetAllPackages } from './packages.network';

export const useLogin = () => {
  // hooks initialization
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Please provide a valid email address!.')
      .required('Please provide your email address!.'),
    password: yup.string().required('Please provide a your account password!.'),
  });

  const initialValues = { email: '', password: '' };

  const { mutate, isLoading } = useMutation(
    async (values: ILoginDTO) => {
      const res = await httpClient.post<ILoginResponse>(
        Endpoints.LOGIN,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(`Welcome back, ${data?.user?.firstname}`);
        const res = data?.access_token;
        const user = data?.user;
        dispatch(updateUser({ ...user, token: res }));
        navigate(Routes.DASHBOARD);
      },
      onError: (error: any) => {
        const message = error?.response?.data?.message;
        toast.error(message);
      },
    }
  );

  const onSubmit = (payload: ILoginDTO) => {
    console.log(payload);
    mutate(payload);
  };

  const { errors, handleSubmit, handleChange, values, dirty, isValid } =
    useFormik({
      onSubmit,
      initialValues,
      validationSchema: schema,
    });

  const disabledButton = !dirty || !isValid;

  return {
    isLoading,
    errors,
    handleSubmit,
    handleChange,
    values,
    disabledButton,
  };
};

export const useRegister = () => {
  // hooks initialization
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedPricing, setSelectedPricing] = useState<Package | null>(null);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Please provide a valid email address!.')
      .required('Please provide your email address!.'),
    password: yup.string().required('Please provide a your account password!.'),
    lastname: yup
      .string()
      .required('Last Name is required!.')
      .matches(/^[aA-zZ]+$/, 'Only Letters are allowed'),
    firstname: yup
      .string()
      .required('First Name is required!.')
      .matches(/^[aA-zZ]+$/, 'Only Letters are allowed'),
    middlename: yup
      .string()
      .matches(/^[aA-zZ]+$/, 'Only Letters are allowed')
      .notRequired(),
    phone: yup
      .string()
      .required('Phone Number is required!.')
      .matches(/^[0-9]+$/, 'Only Numbers are allowed')
      .min(11, ' Phone Number cannot be less than 11 digits')
      .max(13, ' Phone Number cannot be longer than 13 digits'),
    country: yup.string().required('Country is required!.'),
    state: yup.string().required('State is required!.'),
    storename: yup.string().required('Store Name is required!.'),
    business: yup.string().required('Business Type is required!.'),
    package: yup.string().required('Please select a prefererred Package..'),
    websiteurl: yup.string().notRequired(),
    terms_and_condition: yup
      .boolean()
      .required('Please agree to our Terms and Conditions!.'),
  });

  const initialValues: IRegisterDTO = {
    address: '',
    country: '',
    email: '',
    firstname: '',
    gender: '',
    lastname: '',
    lga: '',
    middlename: '',
    avatar: '',
    password: '',
    phone: '',
    state: '',
    storename: '',
    newsletter: false,
    terms_and_condition: false,
    website_url: '',
    business: '',
    package: '',
  };

  const { mutate, isLoading } = useMutation(
    async (values: IRegisterDTO) => {
      const res = await httpClient.post<IRegisterResponse>(
        Endpoints.REGISTER,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        dispatch(updateUserEmail({ email: data?.email }));
        navigate(Routes.ACCOUNT_CREATED_SUCCESS);
      },
      onError: (error: any) => {
        const message = error?.response?.data?.message;
        toast.error(message);
      },
    }
  );

  const onSubmit = (payload: IRegisterDTO) => {
    console.log(payload);
    mutate(payload);
  };

  const {
    errors,
    handleSubmit,
    handleChange,
    values,
    dirty,
    isValid,
    setFieldValue,
  } = useFormik({
    onSubmit,
    initialValues,
    validationSchema: schema,
  });

  const disabledButton = !dirty || !isValid;

  const { cities, countries, states } = useGetCountriesAndStates({
    country: values.country,
    state: values.state,
  });

  const { isLoading: isLaodingBusinesses, businesses } = useGetAllBusinesses();
  const { isLoading: isLoadingPackages, packages } = useGetAllPackages();

  const cityOptions = cities.map((city) => ({
    key: city,
    value: city,
  }));

  const businessOptions = businesses.map((business) => ({
    key: business.name,
    value: business._business_id,
  }));

  const stateOptions = states.map((state) => ({
    key: state,
    value: state,
  }));

  const countryOptions = countries.map((country) => ({
    key: country,
    value: country,
  }));

  const packageOptions = packages?.map((pkg) => ({
    key: pkg.name,
    value: pkg?._package_id.toString(),
  }));

  useEffect(() => {
    if (values.package) {
      setSelectedPricing(
        packages.filter((pkg) => pkg._package_id === Number(values.package))[0]
      );
    }
  }, [packages, values.package]);

  console.log(selectedPricing, values.package);

  return {
    isLoading,
    errors,
    handleSubmit,
    handleChange,
    values,
    disabledButton,
    setFieldValue,
    countryOptions,
    cityOptions,
    stateOptions,
    isLaodingBusinesses,
    businessOptions,
    packageOptions,
    isLoadingPackages,
    selectedPricing,
  };
};

export const useVerifyAccount = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const email = searchParams.get('email') ?? '';
  const token = searchParams.get('token') ?? '';

  const { mutate, isLoading } = useMutation(
    async (values: { email: string; token: string }) => {
      const res = await httpClient.post<IRegisterResponse>(
        Endpoints.VERIFY_ACCOUNT,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        setSuccess(true);
        setTimeout(() => {
          navigate('/auth/login');
        }, 2000);
      },
      onError: (error: any) => {
        const message = error?.response?.data?.message;
        toast.error(message);
        setError(message);
      },
    }
  );

  const onSubmit = () => {
    mutate({ email, token });
  };
  useEffect(() => {
    if (email && token) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, success, error };
};
