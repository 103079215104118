import { useHttpPrivate } from '@network/api';
import {
  BaseError,
  Endpoints,
  IDashboard,
  IGetDashboardResponse,
} from '@network/interface';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

export const useGetDashboardDetails = () => {
  const httpPrivate = useHttpPrivate();
  const [dashboardDetails, setDashboardDetails] = useState<IDashboard | null>(
    null
  );

  const { isLoading, refetch } = useQuery(
    ['dahs'],
    async () => {
      const { data } = await httpPrivate.get<IGetDashboardResponse>(
        Endpoints.DASHBOARD_DETAILS
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setDashboardDetails(data);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  return { dashboardDetails, isLoading, refetch };
};
