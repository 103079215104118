import { useHttpPrivate } from '@network/api';
import {
  BaseError,
  Branch,
  Endpoints,
  ICreateBranchDto,
  ICreateBranchReponse,
  IGetAllBranchesResponse,
  IGetBranchesPaginated,
  IGetSingleBranch,
  PaginationData,
  QueryKeys,
} from '@network/interface';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import * as yup from 'yup';
import { useGetCountriesAndStates } from './country.network';

export const useGetBranchPaginated = () => {
  const httpPrivate = useHttpPrivate();
  const [paginator, setPaginator] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const [branches, setBranches] = useState<Branch[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationData | null>(
    null
  );

  const { isLoading } = useQuery(
    [QueryKeys.GET_BRANCHES_PAGINATED],
    async () => {
      const { data } = await httpPrivate.get<IGetBranchesPaginated>(
        `${Endpoints.GET_BRANCHES_PAGINATED}?page=${paginator.currentPage}&limit=${paginator.pageSize}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setBranches(data.data);
        setPaginationData(data?.pagination);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const updatePage = (page?: number, pageSize?: number) => {
    setPaginator((prev) => ({
      currentPage: page || prev.currentPage,
      pageSize: pageSize || prev.pageSize,
    }));
  };

  return { isLoading, branches, paginationData, paginator, updatePage };
};

export const useGetAllBranches = () => {
  const httpPrivate = useHttpPrivate();

  const [branches, setBranches] = useState<Branch[]>([]);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_BRANCHES],
    async () => {
      const { data } = await httpPrivate.get<IGetAllBranchesResponse>(
        `${Endpoints.GET_ALL_BRANCHES}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setBranches(data.data);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  return { isLoading, branches };
};

export const useCreateBranch = ({
  prev,
  handleCloseAddBranchModal,
}: {
  prev?: Branch | null;
  handleCloseAddBranchModal: () => void;
}) => {
  const httpPrivate = useHttpPrivate();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Please provide a valid email address!.')
      .required('Email address is required!.'),
    address: yup.string().required('Address is required!.'),
    city: yup.string().required('City is required!.'),
    state: yup.string().required('State is required!.'),
    country: yup.string().required('Country is required!.'),
    isHeadBranch: yup.string().required('Head Branch is required!.'),
    name: yup.string().required('Branch Name is required!.'),
    status: yup.string().required('Status is required!.'),
  });

  const initialValues: ICreateBranchDto = {
    email: prev ? prev?.email : '',
    address: prev ? prev?.address : '',
    city: prev ? prev?.city : '',
    state: prev ? prev?.state : '',
    country: prev ? prev?.country : '',
    isHeadBranch: prev ? Boolean(prev?.headBranch) : false,
    name: prev ? prev?.name : '',
    phone: prev ? prev?.phone : '',
    status: prev ? prev?.status : '',
  };

  useEffect(() => {
    if (prev) {
      setFieldValue('email', prev?.email || '');
      setFieldValue('address', prev?.address || '');
      setFieldValue('city', prev?.city || '');
      setFieldValue('state', prev?.state || '');
      setFieldValue('country', prev?.country || '');
      setFieldValue('isHeadBranch', Boolean(prev?.headBranch) || false);
      setFieldValue('name', prev?.name || '');
      setFieldValue('phone', prev?.phone || '');
      setFieldValue('status', prev?.status || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev]);

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    async (values: ICreateBranchDto) => {
      const res = await httpPrivate.post<ICreateBranchReponse>(
        Endpoints.CREATE_BRANCH,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries([QueryKeys.GET_BRANCHES_PAGINATED]);
        handleCloseAddBranchModal();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { isLoading: isEditLoading, mutate: update } = useMutation(
    async (values: ICreateBranchDto) => {
      const res = await httpPrivate.put<ICreateBranchReponse>(
        `${Endpoints.EDIT_BRANCH}/${prev?._branch_id}`,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries([QueryKeys.GET_BRANCHES_PAGINATED]);
        handleCloseAddBranchModal();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = (payload: ICreateBranchDto) => {
    mutate({
      ...payload,
      isHeadBranch: payload.isHeadBranch.toString() === 'true' ? true : false,
    });
  };

  const onEditSubmit = (payload: ICreateBranchDto) => {
    console.log(payload);
    update({
      ...payload,
      isHeadBranch: payload.isHeadBranch.toString() === 'true' ? true : false,
    });
  };

  const {
    errors,
    handleSubmit,
    handleChange,
    values,
    dirty,
    isValid,
    setFieldValue,
  } = useFormik({
    onSubmit: prev ? onEditSubmit : onSubmit,
    initialValues,
    validationSchema: schema,
  });

  const { cities, countries, states } = useGetCountriesAndStates({
    country: values.country,
    state: values.state,
  });

  const isDisabled = !dirty || !isValid;
  console.log(dirty, isValid);

  const isEdit = !!prev;

  const stateOptions =
    states?.map((state) => ({ key: state, value: state })) ?? [];

  const statusOptions = [
    { key: 'ACTIVE', value: 'ACTIVE' },
    { key: 'INACTIVE', value: 'INACTIVE' },
  ];

  const countryOptions =
    countries?.map((country) => ({ key: country, value: country })) ?? [];
  const citiesOptions =
    cities?.map((cities) => ({ key: cities, value: cities })) ?? [];

  const headBranchOptions = [
    { key: 'YES', value: true },
    { key: 'NO', value: false },
  ];

  console.log(values);

  return {
    isLoading: isLoading || isEditLoading,
    handleChange,
    errors,
    isDisabled,
    values,
    isEdit,
    handleSubmit,
    // handleEditBranch: () => {
    //   console.log('submit edit');
    // },
    countryOptions,
    stateOptions,
    statusOptions,
    citiesOptions,
    headBranchOptions,
  };
};

export const useGetSingleBranchById = ({ id = '' }) => {
  const httpPrivate = useHttpPrivate();

  const [branch, setBranch] = useState<Branch | null>(null);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_BRANCHES, { branch }],
    async () => {
      const { data } = await httpPrivate.get<IGetSingleBranch>(
        `${Endpoints.GET_SINGLE_BRANCH}?id=${id}`
      );
      return data;
    },
    {
      enabled: !id ? false : true,
      onSuccess: (data) => {
        setBranch(data.data);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  return { isLoading, branch };
};

export const useDeleteBranch = ({ callback }: { callback?: () => void }) => {
  const httpPrivate = useHttpPrivate();

  const { isLoading, mutate } = useMutation(
    async (id: string) => {
      const { data } = await httpPrivate.delete<IGetSingleBranch>(
        `${Endpoints.DELETE_BRANCH}/${id}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        callback?.();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleDelete = (id: string) => {
    mutate(id);
  };

  return { isLoading, handleDelete };
};
