import { IUser, Store } from '@network/interface/app';
import { createSlice } from '@reduxjs/toolkit';

const userInitState: IUser = {
  email: '',
  firstname: '',
  _account_id: '',
  _supplier: null,
  address: '',
  country: '',
  createdAt: '',
  enterProduct: false,
  fullname: '',
  gender: '',
  lga: '',
  isDefaultPassword: false,
  middlename: '',
  lastname: '',
  moveDate: null,
  passport: null,
  phone: '',
  state: '',
  status: '',
  token: '',
  updatedAt: '',
  user_type: '',
  username: '',
  _store: {} as Store,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitState,
  reducers: {
    updateUser: (state, action: { payload: Partial<IUser> }) => {
      return { ...state, ...action.payload };
    },
    updateUserEmail: (state, action: { payload: { email: string } }) => {
      return { ...state, user: { ...state, email: action.payload.email } };
    },
    logoutUser: (state) => {
      return userInitState;
    },
  },
});

export const { updateUser, logoutUser, updateUserEmail } = userSlice.actions;
export default userSlice.reducer;
