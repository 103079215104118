import { PageHeader } from '@components/PageHeader';
import { StaffTable } from './tables';

export const StaffManagementPage = () => {
  return (
    <div>
      <PageHeader title='Staff Management' />
      {/* <StaffFilter /> */}
      <StaffTable />
    </div>
  );
};
