import { Spinner } from '@lib/Loaders';
import { useVerifyAccount } from '@network/hooks';

export const VerifyAccountPage = () => {
  const { isLoading, success, error } = useVerifyAccount();
  return (
    <div className='w-4/5 max-h-[80%] bg-white rounded-3xl p-8 shadow-lg'>
      <h2 className='text-3xl font-bold text-primary-500 uppercase'>
        Account Verification
      </h2>
      {/* <h4 className='text-sm text-gray-400'>
        Account Verifi?.
      </h4> */}
      <div className='my-8'>
        <h2 className='text-2xl font-semibold text-center'>
          Hold on while we verify your account?.
        </h2>
      </div>
      <div className='flex items-center justify-between'>
        {isLoading ? <Spinner /> : null}
      </div>
      <div className='flex items-center justify-between'>
        {success ? (
          <div>
            <p>Account Verification Successfull</p>
            <p>Redirecting...</p>
          </div>
        ) : error ? (
          <div>
            <p>Error verifying your account.</p>
            <p>Reason: {error}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};
