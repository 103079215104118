import { Button } from '@lib/Button';
import { BaseInput } from '@lib/Input';
import { Modal, ModalFooter } from '@lib/Modal';
import { BaseSelect } from '@lib/Select';
import { useCreateStaff } from '@network/hooks';
import { Staff } from '@network/interface';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  prev?: Staff | null;
}

export const CreateStaffModal = ({ isOpen, onClose, prev }: IProps) => {
  const {
    isLoading,
    isEdit,
    handleSubmit,
    errors,
    values,
    handleChange,
    isDisabled,
    countryOptions,
    stateOptions,
    citiesOptions,
    genderOptions,
    branchesOptions,
    rolesOptions,
    statusOptions,
  } = useCreateStaff({ prev, handleCloseAddStaffModal: onClose });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isEdit ? `Edit Staff: ${prev?.username}` : 'Add Staff'}>
      <div className='p-4'>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Staff First Name'
            placeholder='Enter staff firstname'
            name='firstname'
            type='firstname'
            onChange={handleChange}
            value={values.firstname}
            error={errors.firstname}
          />
          <BaseInput
            label='Staff Middle Name'
            placeholder='Enter middlename'
            name='middlename'
            type='middlename'
            onChange={handleChange}
            value={values.middlename}
            error={errors.middlename}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Staff Last Name'
            placeholder='Enter staff lastname'
            name='lastname'
            type='lastname'
            onChange={handleChange}
            value={values.lastname}
            error={errors.lastname}
          />
          <BaseInput
            label='Staff Email'
            placeholder='Enter Email'
            name='email'
            type='email'
            onChange={handleChange}
            value={values.email}
            error={errors.email}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Staff Phone Number'
            placeholder='Enter Phone Number'
            name='phone'
            type='phone'
            onChange={handleChange}
            value={values.phone}
            error={errors.phone}
          />
          <BaseSelect
            label='Gender'
            placeholder='Select Gender'
            options={genderOptions}
            name='gender'
            type='gender'
            onChange={handleChange}
            value={values.gender}
            error={errors.gender}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseSelect
            label='Role'
            placeholder='Select Role'
            options={rolesOptions}
            name='role'
            type='role'
            onChange={handleChange}
            value={values.role}
            error={errors.role}
          />
          <BaseSelect
            label='Staff Country'
            placeholder='Select Staff Country'
            options={countryOptions}
            name='country'
            type='country'
            onChange={handleChange}
            value={values.country}
            error={errors.country}
            disabled={!countryOptions || countryOptions.length < 0}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseSelect
            label='Staff State'
            placeholder='Select Staff State'
            options={stateOptions}
            name='state'
            type='state'
            onChange={handleChange}
            value={values.state}
            error={errors.state}
            disabled={
              !values.country || !stateOptions || stateOptions.length < 0
            }
          />
          <BaseSelect
            label='Staff LGA'
            placeholder='Select staff LGA'
            options={citiesOptions}
            name='lga'
            type='lga'
            onChange={handleChange}
            value={values.lga}
            error={errors.lga}
            disabled={
              !values.state || !citiesOptions || citiesOptions.length < 0
            }
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Staff Address'
            placeholder='Enter Staff Address'
            name='address'
            type='address'
            onChange={handleChange}
            value={values.address}
            error={errors.address}
          />
          <BaseSelect
            label='Branch'
            placeholder='Select Branch'
            options={branchesOptions}
            name='branch'
            type='branch'
            onChange={handleChange}
            value={values.branch}
            error={errors.branch}
          />
        </div>
        <BaseSelect
          label='status'
          placeholder='Select Status'
          options={statusOptions}
          name='status'
          type='status'
          onChange={handleChange}
          value={values.status}
          error={errors.status}
        />
      </div>
      <ModalFooter>
        <Button
          label={isEdit ? 'Save Staff' : 'Create Staff'}
          isLoading={isLoading}
          disabled={isDisabled}
          onClick={handleSubmit}
          type='submit'
        />
      </ModalFooter>
    </Modal>
  );
};
