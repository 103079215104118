import { Button } from '@lib/Button';
import { BaseInput } from '@lib/Input';
import { useLogin } from '@network/hooks';
import { Link } from 'react-router-dom';

export const LoginPage = () => {
  const {
    disabledButton,
    errors,
    handleChange,
    handleSubmit,
    isLoading,
    values,
  } = useLogin();

  return (
    <div className='w-4/5 max-h-[80%] bg-white rounded-3xl p-8 shadow-lg'>
      <h2 className='text-3xl font-bold text-primary-500 uppercase'>LOGIN</h2>
      <h4 className='text-sm text-gray-400'>
        Access your store using your email and password.
      </h4>
      <div className='mt-3'>
        <BaseInput
          label='Email'
          placeholder='Enter your email or username'
          name='email'
          type='email'
          onChange={handleChange}
          value={values.email}
          error={errors.email}
        />
        <div className='flex items-center gap-2 text-xs mt-8  mb-[-1.5rem] justify-end'>
          <Link
            to='/auth/forgot-password'
            className='text-primary-500 hover:text-primary-600 cursor-pointer'>
            Forgot password?
          </Link>
        </div>
        <BaseInput
          label='Password'
          name='password'
          placeholder='Enter your password'
          type='password'
          onChange={handleChange}
          value={values.password}
          error={errors.password}
        />
      </div>
      <Button
        label='LOGIN'
        onClick={handleSubmit}
        disabled={disabledButton}
        isLoading={isLoading}
        loadingText='signing in...'
      />
      <div className='flex items-center gap-2 text-xs mt-5 justify-end'>
        <p className='text-gray-400'>Don't have an account?</p>
        <Link
          className='text-primary-500 hover:text-primary-600'
          to='/auth/register'>
          Create Account.
        </Link>
      </div>
    </div>
  );
};
