import { Close } from '@mui/icons-material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import BaseModal from '@mui/material/Modal';
import { animated, useSpring } from '@react-spring/web';
import useBreakpointValue from '@utils/useBreakpoint';
import * as React from 'react';
import styles from './modal.module.css';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props,
  ref
) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

interface IProps {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  children: any;
  size?: 'lg' | 'sm';
}

export const Modal = ({
  isOpen,
  onClose,
  children,
  size = 'sm',
  title,
}: IProps) => {
  const sm = useBreakpointValue({
    base: '95%',
    md: '50%',
    lg: '40%',
    xl: '40%',
    xxl: '30%',
  });
  const lg = useBreakpointValue({
    base: '95%',
    md: '70%',
    lg: '70%',
    xl: '60%',
    xxl: '40%',
  });
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: size === 'sm' ? sm : size === 'lg' ? lg : '70%',
    bgcolor: 'background.paper',
    border: '1px solid #DEDEDE',
    boxShadow: 24,
    p: 0,
    borderRadius: 2,
    maxHeight: '80vh',
    overflowY: 'auto',
  };

  return (
    <BaseModal
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}>
      <Fade in={isOpen}>
        <Box sx={style} className={`${styles.scrollbar_thinner}`}>
          <div className='flex justify-between border-b-[0.5px] p-4 border-gray-100 mb-4 sticky top-0 bg-white'>
            <p className='text-lg font-semibold'>{title}</p>
            <div
              onClick={onClose}
              className='bg-slate-200 hover:bg-slate-400 cursor-pointer hover:text-white text-slate-600 rounded-full w-max h-max p-2 flex items-center transition duration-300'>
              <Close className='!h-4 !w-4' />
            </div>
          </div>
          {children}
        </Box>
      </Fade>
    </BaseModal>
  );
};

interface ModalFooterProps {
  children: any;
}

export const ModalFooter = ({ children }: ModalFooterProps) => {
  return (
    <section
      className={`sticky bottom-0 flex p-4 py-5 space-x-2 mt-4 border-t border-t-[#ECECEC] bg-[#FAFAFA] items-center justify-end`}>
      <div className='flex items-center justify-end gap-4'>{children}</div>
    </section>
  );
};
