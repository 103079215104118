import React from 'react';

import { PageHeader } from '@components/PageHeader';
import { RoleTable } from './tables';

export const RoleManagementPage = () => {
  return (
  <div>
    <PageHeader title='Role Management' />
    <RoleTable/>
  </div>
  );
};
