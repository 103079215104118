import { Button } from '@lib/Button';
import { BaseInput } from '@lib/Input';
import { Modal, ModalFooter } from '@lib/Modal';
import { useCreateExpense } from '@network/hooks';
import { Expenses } from '@network/interface';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  prev?: Expenses | null;
}

export const CreateExpensesModal = ({ isOpen, onClose, prev }: IProps) => {
  const {
    isLoading,
    isEdit,
    handleSubmit,
    errors,
    values,
    handleChange,
    isDisabled,
  } = useCreateExpense({ prev, handleCloseModal: onClose });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isEdit ? `Edit Expense: ${prev?._expense_id}` : 'Add Expense'}>
      <div className='p-4'>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Category'
            placeholder='Enter Expense Category'
            name='category'
            type='text'
            onChange={handleChange}
            value={values.category}
            error={errors.category}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Amount'
            placeholder='Enter Expense Amount'
            name='amount'
            type='number'
            onChange={handleChange}
            value={values.amount}
            error={errors.amount}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Description'
            placeholder='Enter Description'
            name='description'
            type='text'
            onChange={handleChange}
            value={values.description}
            error={errors.description}
            optional
          />
        </div>
      </div>
      <ModalFooter>
        <Button
          label={isEdit ? 'Save Expense' : 'Create Expense'}
          isLoading={isLoading}
          disabled={isDisabled}
          onClick={handleSubmit}
          type='submit'
        />
      </ModalFooter>
    </Modal>
  );
};
