import { Button } from '@lib/Button';
import { BaseInput } from '@lib/Input';
import { Modal, ModalFooter } from '@lib/Modal';
import { BaseSelect } from '@lib/Select';
import { useCreateSupplier } from '@network/hooks';
import { Supplier } from '@network/interface';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  prev?: Supplier | null;
}

export const CreateSupplierModal = ({ isOpen, onClose, prev }: IProps) => {
  const {
    isLoading,
    isEdit,
    handleSubmit,
    errors,
    values,
    handleChange,
    isDisabled,
    statusOptions,
  } = useCreateSupplier({ prev, handleCloseModal: onClose });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        isEdit
          ? `Edit Supplier: ${prev?.firstname} ${prev?.lastname}`
          : 'Add Supplier'
      }>
      <div className='p-4'>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='First Name'
            placeholder='Enter First Name'
            name='firstname'
            type='text'
            onChange={handleChange}
            value={values.firstname}
            error={errors.firstname}
          />
          <BaseInput
            label='Middle Name'
            placeholder='Enter Middle Name'
            name='middlename'
            type='text'
            onChange={handleChange}
            value={values.middlename}
            error={errors.middlename}
            optional
          />
          <BaseInput
            label='Last Name'
            placeholder='Enter Last Name'
            name='lastname'
            type='text'
            onChange={handleChange}
            value={values.lastname}
            error={errors.lastname}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Email'
            placeholder='Enter Email'
            name='email'
            type='email'
            onChange={handleChange}
            value={values.email}
            error={errors.email}
          />
          <BaseInput
            label='Phone Number'
            placeholder='Enter Phone Number'
            name='phone'
            type='number'
            onChange={handleChange}
            value={values.phone}
            error={errors.phone}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Address'
            placeholder='Enter Address'
            name='address'
            type='text'
            onChange={handleChange}
            value={values.address}
            error={errors.address}
          />
          <BaseSelect
            label='Status'
            placeholder='Select Status'
            options={statusOptions}
            name='status'
            type='text'
            onChange={handleChange}
            value={values.status}
            error={errors.status}
          />
        </div>
      </div>
      <ModalFooter>
        <Button
          label={isEdit ? 'Save Supplier' : 'Create Supplier'}
          isLoading={isLoading}
          disabled={isDisabled}
          onClick={handleSubmit}
          type='submit'
        />
      </ModalFooter>
    </Modal>
  );
};
