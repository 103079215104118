import { useHttpPrivate } from '@network/api';
import {
  BaseError,
  Endpoints,
  ICreateSupplierDto,
  ICreateSupplierResponse,
  IGetAllSuppliersResponse,
  IGetSingleSupplier,
  IGetSuppliersPaginatedResponse,
  PaginationData,
  QueryKeys,
  Supplier,
} from '@network/interface';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import * as yup from 'yup';

export const useGetSuppliersPaginated = () => {
  const httpPrivate = useHttpPrivate();
  const [paginator, setPaginator] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationData | null>(
    null
  );

  const { isLoading } = useQuery(
    [QueryKeys.GET_SUPPLIERS_PAGINATED],
    async () => {
      const { data } = await httpPrivate.get<IGetSuppliersPaginatedResponse>(
        `${Endpoints.GET_SUPPLIERS_PAGINATED}?page=${paginator.currentPage}&limit=${paginator.pageSize}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setSuppliers(data.data);
        setPaginationData(data?.pagination);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const updatePage = (page?: number, pageSize?: number) => {
    setPaginator((prev) => ({
      currentPage: page || prev.currentPage,
      pageSize: pageSize || prev.pageSize,
    }));
  };

  return { isLoading, suppliers, paginationData, paginator, updatePage };
};

export const useGetAllSuppliers = () => {
  const httpPrivate = useHttpPrivate();

  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_SUPPLIERS],
    async () => {
      const { data } = await httpPrivate.get<IGetAllSuppliersResponse>(
        `${Endpoints.GET_ALL_SUPPLIERS}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setSuppliers(data.data);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  return { isLoading, suppliers };
};

export const useCreateSupplier = ({
  prev,
  handleCloseModal,
}: {
  prev?: Supplier | null;
  handleCloseModal: () => void;
}) => {
  const httpPrivate = useHttpPrivate();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Please provide a valid email address!.')
      .required('Email address is required!.'),
    status: yup.string().required('This field is required!.'),
    address: yup.string().notRequired(),
    firstname: yup.string().required('This field is required!.'),
    lastname: yup.string().required('This field is required!.'),
    middlename: yup.string().notRequired(),
    phone: yup.string().required('This field is required!.'),
  });

  const initialValues: ICreateSupplierDto = {
    address: prev ? prev?.address : '',
    email: prev ? prev?.email : '',
    firstname: prev ? prev?.firstname : '',
    lastname: prev ? prev?.lastname : '',
    middlename: prev ? prev?.middlename : '',
    phone: prev ? prev?.phone : '',
    status: prev ? prev?.status : '',
  };

  useEffect(() => {
    setFieldValue('address', prev?.address || '');
    setFieldValue('email', prev?.email || '');
    setFieldValue('firstname', prev?.firstname || '');
    setFieldValue('lastname', prev?.lastname || '');
    setFieldValue('middlename', prev?.middlename || '');
    setFieldValue('phone', prev?.phone || '');
    setFieldValue('status', prev?.status || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev]);

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    async (values: ICreateSupplierDto) => {
      const res = await httpPrivate.post<ICreateSupplierResponse>(
        Endpoints.CREATE_SUPPLIER,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries([QueryKeys.GET_SUPPLIERS_PAGINATED]);
        handleCloseModal();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { isLoading: isEditLoading, mutate: update } = useMutation(
    async (values: ICreateSupplierDto) => {
      const res = await httpPrivate.put<ICreateSupplierResponse>(
        `${Endpoints.EDIT_SUPPLIER}/${prev?._supplier_id}`,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries([QueryKeys.GET_SUPPLIERS_PAGINATED]);
        handleCloseModal();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = (payload: ICreateSupplierDto) => {
    mutate(payload);
  };

  const onEditSubmit = (payload: ICreateSupplierDto) => {
    update(payload);
  };

  const {
    errors,
    handleSubmit,
    handleChange,
    values,
    dirty,
    isValid,
    setFieldValue,
  } = useFormik({
    onSubmit: prev ? onEditSubmit : onSubmit,
    initialValues,
    validationSchema: schema,
  });

  const isDisabled = !dirty || !isValid;

  const isEdit = !!prev;

  const statusOptions = [
    { key: 'ACTIVE', value: 'ACTIVE' },
    { key: 'INACTIVE', value: 'INACTIVE' },
  ];

  return {
    isLoading: isLoading || isEditLoading,
    handleChange,
    errors,
    isDisabled,
    values,
    isEdit,
    handleSubmit,
    statusOptions,
  };
};

export const useGetSingleSupplierById = ({ id = '' }) => {
  const httpPrivate = useHttpPrivate();

  const [supplier, setSupplier] = useState<Supplier | null>(null);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_SUPPLIERS, { supplier }],
    async () => {
      const { data } = await httpPrivate.get<IGetSingleSupplier>(
        `${Endpoints.GET_SINGLE_SUPPLIER}/${id}`
      );
      return data;
    },
    {
      enabled: !id ? false : true,
      onSuccess: (data) => {
        setSupplier(data.data);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  return { isLoading, supplier };
};

export const useDeleteSupplier = ({ callback }: { callback?: () => void }) => {
  const httpPrivate = useHttpPrivate();

  const { isLoading, mutate } = useMutation(
    async (id: string) => {
      const { data } = await httpPrivate.delete<IGetSingleSupplier>(
        `${Endpoints.DELETE_SUPPLER}/${id}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        callback?.();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleDelete = (id: string) => {
    mutate(id);
  };

  return { isLoading, handleDelete };
};
