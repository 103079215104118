import React from 'react';

interface IProps {
  title: string;
}

export const PageHeader = ({ title }: IProps) => {
  return (
    <div className='w-full bg-white dark:bg-slate-800 rounded-md p-6 mb-2 flex items-center'>
      <h2 className='text-2xl font-bold uppercase text-primary-500 dark:text-white'>{title}</h2>
    </div>
  );
};
