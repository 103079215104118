import { MOMENT_FORMAT } from '@app/helpers';
import { useDisclosure } from '@hooks/useDisclosure';
import { Button } from '@lib/Button';
import { Table, TableActionMenu } from '@lib/Table';
import { useGetExpensesPaginated } from '@network/hooks';
import { Expenses } from '@network/interface';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { CreateExpensesModal } from '../modals';

export const ExpensesTable = () => {
  const {
    isOpen: isOpenAddExpensesModal,
    onClose: onCloseAddExpensesModal,
    onOpen: onOpenAddExpensesModal,
  } = useDisclosure();

  const [selected, setSelected] = useState<Expenses | null>(null);

  const handleEditClick = (val: Expenses) => {
    setSelected(val);
    onOpenAddExpensesModal();
  };

  // const handleDeleteClick = (id: Expenses) => {
  //   setSelected(id);
  //   // onOpenConfirmDeleteModal();
  // };

  // const confirmDelete = () => {
  //   // call delete function from delete hook
  // handleDelete(selected?._expense_id!)
  // };

  const { expenses, isLoading, paginationData, updatePage } =
    useGetExpensesPaginated();

  const columns = useMemo<ColumnDef<Expenses>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: '_expense_id',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Expenses',
        accessorKey: 'category',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Date Added',
        accessorKey: 'createdAt',
        cell: (info) => moment(info.getValue() as string).format(MOMENT_FORMAT),
      },
      {
        header: 'Last Updated',
        accessorKey: 'updatedAt',
        cell: (info) => moment(info.getValue() as string).format(MOMENT_FORMAT),
      },
      {
        header: 'Action',
        accessorFn: (row) => row,
        cell: (info) => {
          return (
            <TableActionMenu
              actions={['EDIT']}
              actionHandler={{
                handleEdit: handleEditClick,
              }}
              value={info.cell.getValue() as string}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //   const { branches, isLoading, paginationData, updatePage } =
  //     useGetBranchPaginated();

  return (
    <>
      {/* <div>
        <BaseSelect
          options={[10, 20, 30, 40, 50, 100].map((val) => ({
            key: val.toString(),
            value: val.toString(),
          }))}
        />
      </div> */}
      <div className='w-full bg-white flex items-center justify-end gap-5 my-5 rounded-md border border-slate-200 p-4 dark:border-slate-300'>
        <div className='w-[15%]'>
          <Button label='Add Expense' onClick={onOpenAddExpensesModal} />
        </div>
      </div>
      <Table
        columns={columns}
        data={expenses}
        totalPageCount={paginationData?.total_pages}
        updatePage={updatePage}
        isLoading={isLoading}
      />
      <CreateExpensesModal
        isOpen={isOpenAddExpensesModal}
        onClose={onCloseAddExpensesModal}
        prev={selected}
      />
      {/* confirm delete modal */}
    </>
  );
};
