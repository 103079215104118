import { PageHeader } from '@components/PageHeader';
import { ExpensesTable } from './tables';

export const ExpensesManagementPage = () => {
  return (
    <div>
      <PageHeader title='Expenses Management' />
      <ExpensesTable />
    </div>
  );
};
