import { PageHeader } from '@components/PageHeader';
import { ManageCustomersTable } from './tables';

export const CustomerManagementPage = () => {
  return (
    <div>
      <PageHeader title='Manage Customers' />
      <ManageCustomersTable />
    </div>
  );
};
