import { useHttpPrivate } from '@network/api';
import {
  BaseError,
  Business,
  Endpoints,
  GetAllBusinessReponse,
  QueryKeys,
} from '@network/interface';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';

export const useGetAllBusinesses = () => {
  const httpPrivate = useHttpPrivate();

  const [businesses, setBusinesses] = useState<Business[]>([]);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_BUSINESS],
    async () => {
      const { data } = await httpPrivate.get<GetAllBusinessReponse>(
        `${Endpoints.GET_ALL_BUSINESS}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setBusinesses(data.data);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  return { isLoading, businesses };
};
