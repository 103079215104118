import axios from 'axios';

export const BASE_URL = 'https://api.cube700.net/api/v1';
// export const BASE_URL = 'http://localhost:9091/api/v1';

const headers = {};

export const httpClient = axios.create({
  baseURL: BASE_URL,
  headers,
});
