import { useHttpPrivate } from '@network/api';
import {
  BaseError,
  Endpoints,
  IGetAllPackagesResponse,
  Package,
  QueryKeys,
} from '@network/interface';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';

export const useGetAllPackages = () => {
  const httpPrivate = useHttpPrivate();

  const [packages, setPackages] = useState<Package[]>([]);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_PACKAGES],
    async () => {
      const { data } = await httpPrivate.get<IGetAllPackagesResponse>(
        `${Endpoints.GET_ALL_PACKAGES}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setPackages(data.data);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  return { isLoading, packages };
};
