import { PageHeader } from '@components/PageHeader';
import { ReportsTable } from './tables';

export const ReportsManagementPage = () => {
  return (
    <div>
      <PageHeader title='Reports Management' />
      <ReportsTable />
    </div>
  );
};
