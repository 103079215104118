import { useHttpPrivate } from '@network/api';
import {
  BaseError,
  Category,
  Endpoints,
  ICreateProductCategoryDto,
  ICreateProductCategoryResponse,
  IGetAllCategoriesResponse,
  IGetCategoriesPaginatedResponse,
  IGetSingleCategory,
  PaginationData,
  QueryKeys,
} from '@network/interface';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import * as yup from 'yup';

export const useCreateProductCategory = ({
  prev,
  handleCloseModal,
}: {
  prev?: Category | null;
  handleCloseModal: () => void;
}) => {
  const httpPrivate = useHttpPrivate();

  const schema = yup.object().shape({
    status: yup.string().required('Status is required!.'),
    name: yup.string().required('Status is required!.'),
    description: yup.string().notRequired(),
  });

  const initialValues: ICreateProductCategoryDto = {
    name: prev ? prev?.name : '',
    description: prev ? prev?.description : '',
    status: prev ? prev?.status : '',
  };

  useEffect(() => {
    if (prev) {
      setFieldValue('name', prev?.name || '');
      setFieldValue('description', prev?.description || '');
      setFieldValue('status', prev?.status || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev]);

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    async (values: ICreateProductCategoryDto) => {
      const res = await httpPrivate.post<ICreateProductCategoryResponse>(
        Endpoints.CREATE_PRODUCT_CATEGORY,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries([
          QueryKeys.GET_ALL_PRODUCT_CATEGORIES_PAGINATED,
        ]);
        handleCloseModal();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { isLoading: isEditLoading, mutate: update } = useMutation(
    async (values: ICreateProductCategoryDto) => {
      const res = await httpPrivate.put<ICreateProductCategoryResponse>(
        `${Endpoints.EDIT_PRODUCT_CATEGORY}/${prev?._category_id}`,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        queryClient.invalidateQueries([
          QueryKeys.GET_ALL_PRODUCT_CATEGORIES_PAGINATED,
        ]);
        handleCloseModal();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = (payload: ICreateProductCategoryDto) => {
    console.log(payload);
    mutate(payload);
  };

  const onEditSubmit = (payload: ICreateProductCategoryDto) => {
    console.log(payload);
    update(payload);
  };

  const {
    errors,
    handleSubmit,
    handleChange,
    values,
    dirty,
    isValid,
    setFieldValue,
  } = useFormik({
    onSubmit: prev ? onEditSubmit : onSubmit,
    initialValues,
    validationSchema: schema,
  });

  const isDisabled = !dirty || !isValid;

  const isEdit = !!prev;

  const statusOptions = [
    { key: 'ACTIVE', value: 'ACTIVE' },
    { key: 'INACTIVE', value: 'INACTIVE' },
  ];

  return {
    isLoading: isLoading || isEditLoading,
    handleChange,
    errors,
    isDisabled,
    values,
    isEdit,
    handleSubmit,
    statusOptions,
  };
};

export const useGetAllProductCategories = () => {
  const httpPrivate = useHttpPrivate();

  const [categories, setCategories] = useState<Category[]>([]);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_PRODUCT_CATEGORIES],
    async () => {
      const { data } = await httpPrivate.get<IGetAllCategoriesResponse>(
        `${Endpoints.GET_ALL_PRODUCT_CATEGORIES}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setCategories(data.data);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  return { isLoading, categories };
};

export const useGetAllCategoriesPaginated = () => {
  const httpPrivate = useHttpPrivate();
  const [paginator, setPaginator] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const [categories, setCategories] = useState<Category[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationData | null>(
    null
  );

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_PRODUCT_CATEGORIES_PAGINATED],
    async () => {
      const { data } = await httpPrivate.get<IGetCategoriesPaginatedResponse>(
        `${Endpoints.GET_PRODUCTS_CATEGORIES_PAGINATED}?page=${paginator.currentPage}&limit=${paginator.pageSize}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setCategories(data.data);
        setPaginationData(data?.pagination);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const updatePage = (page?: number, pageSize?: number) => {
    setPaginator((prev) => ({
      currentPage: page || prev.currentPage,
      pageSize: pageSize || prev.pageSize,
    }));
  };

  return { isLoading, categories, paginationData, paginator, updatePage };
};

export const useGetSingleCategoryById = ({ id = '' }) => {
  const httpPrivate = useHttpPrivate();

  const [category, setCategory] = useState<Category | null>(null);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_PRODUCT_CATEGORIES, { category }],
    async () => {
      const { data } = await httpPrivate.get<IGetSingleCategory>(
        `${Endpoints.GET_SINGLE_PRODUCT_CATEGORY}/${id}`
      );
      return data;
    },
    {
      enabled: !id ? false : true,
      onSuccess: (data) => {
        setCategory(data.data);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  return { isLoading, category };
};

export const useDeleteCategory = ({ callback }: { callback?: () => void }) => {
  const httpPrivate = useHttpPrivate();

  const { isLoading, mutate } = useMutation(
    async (id: string) => {
      const { data } = await httpPrivate.delete<IGetSingleCategory>(
        `${Endpoints.DELETE_PRODUCT_CATEGORY}/${id}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message);
        callback?.();
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleDelete = (id: string) => {
    mutate(id);
  };

  return { isLoading, handleDelete };
};
