import { Button } from '@lib/Button';
import { BaseInput } from '@lib/Input';
import { Modal, ModalFooter } from '@lib/Modal';
import { BaseSelect } from '@lib/Select';
import { Close } from '@mui/icons-material';
import { useCreateSales } from '@network/hooks';
import { Sales } from '@network/interface';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  prev?: Sales | null;
}

export const CreateSalesModal = ({ isOpen, onClose, prev }: IProps) => {
  const {
    isLoading,
    isEdit,
    handleCreate,
    handleEdit,
    errors,
    values,
    handleChange,
    isDisabled,
    setFieldValue,
    paymentOptions,
    items,
    handleAddItem,
    handleRemoveItem,
    handleItemChange,
    selectedProduct,
    isLoadingProducts,
    productOptions,
    getAmount,
    getProductName,
    getTotalPrice,
    customerOptions,
    isLoadingCustomers,
  } = useCreateSales({ prev, handleCloseModal: onClose });

  console.log(selectedProduct);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      // title={isEdit ? `Editing Sales: ${prev}` : 'Add Sales'}>
      title={'Add Sales'}>
      <div className='p-4'>
        <div>
          <div className='px-5'>
            <div className='flex items-center justify-between border-b border-b-gray-500 py-4'>
              <p className='font-bold uppercase text-sm'>S/N</p>
              <p className='font-bold uppercase text-sm'>ITEM</p>
              <p className='font-bold uppercase text-sm'>QUANTITY</p>
              <p className='font-bold uppercase text-sm'>AMOUNT</p>
              <span className='w-5' />
            </div>
            {items?.map((item, _idx) => (
              <div className='flex items-center justify-between' key={_idx}>
                <p>{_idx + 1}</p>
                <p>{getProductName(item?.productId)}</p>
                <p>{item?.piecesSold}</p>
                <p className='flex items-center gap-5'>{getAmount(item)}</p>
                <span
                  className='w-5 text-red-500 cursor-pointer text-xs'
                  onClick={() => handleRemoveItem(item?.productId.toString())}>
                  <Close />
                </span>
              </div>
            ))}
          </div>
          <div className='flex justify-between gap-4'>
            <BaseSelect
              label='Product'
              placeholder='Select a product'
              name='productId'
              type='text'
              value={selectedProduct?.productId ?? ''}
              options={productOptions}
              onChange={handleItemChange}
              disabled={!productOptions || isLoadingProducts}
            />
            <BaseInput
              label='Quantity'
              placeholder='Enter quantity'
              name='piecesSold'
              type='number'
              value={selectedProduct?.piecesSold ?? ''}
              onChange={handleItemChange}
            />
          </div>
          <Button
            label='ADD PRODUCT'
            disabled={
              !selectedProduct?.piecesSold && !selectedProduct?.productId
            }
            onClick={handleAddItem}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseSelect
            label='Customer'
            placeholder='Select a Customer'
            name='customer_id'
            type='text'
            options={customerOptions}
            onChange={handleChange}
            value={values.customer_id}
            error={errors.customer_id}
            disabled={!customerOptions || isLoadingCustomers}
          />
        </div>

        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Note'
            placeholder='Enter Note'
            name='note'
            type='text'
            onChange={handleChange}
            value={values.note}
            error={errors.note}
            optional
          />
          <BaseInput
            label='Amount Paid'
            placeholder='Enter Amount Paid'
            name='amount_paid'
            type='number'
            onChange={handleChange}
            value={values.amount_paid}
            error={errors.amount_paid}
          />
        </div>

        <div>
          <div>
            <p className='text-sm text-gray-400 mb-2'>Payment Method</p>
          </div>
          <div className='flex gap-4'>
            {paymentOptions.map((opt, _idx) => (
              <div
                className='inline-flex relative'
                key={_idx}
                onClick={() => setFieldValue('payment_method', opt.value)}>
                <input
                  className='hidden'
                  type='radio'
                  id={opt.label}
                  value={opt.value}
                  name='payment_method'
                  checked={values.payment_method === opt.value}
                />
                <label
                  className={`border border-gray-300 hover:border-gray-400 text-gray-300 font-semibold transition duration-200 py-2 px-4 cursor-pointer rounded-lg text-sm ${
                    values.payment_method === opt.value
                      ? 'border-primary-500 hover:border-primary-500 text-primary-500'
                      : ''
                  }`}
                  htmlFor={opt.label}>
                  {opt.label}
                </label>
                {values.payment_method === opt.value ? (
                  <div
                    className={`absolute top-1 right-1 w-2 h-2 transition duration-200 rounded-full ${
                      values.payment_method === opt.value
                        ? 'bg-primary-500'
                        : 'bg-gray-300'
                    }`}
                  />
                ) : null}
              </div>
            ))}
          </div>
          {errors?.payment_method ? (
            <small className={`ml-1 text-red-500 text-xs`}>
              {errors?.payment_method}
            </small>
          ) : null}
        </div>

        <div className='flex justify-end gap-4'>
          <h1 className='font-bold text-xl'>TOTAL: {getTotalPrice()}</h1>
        </div>
      </div>

      <ModalFooter>
        <Button
          label='Save'
          isLoading={isLoading}
          disabled={isDisabled}
          onClick={isEdit ? handleEdit : handleCreate}
          type='submit'
        />
      </ModalFooter>
    </Modal>
  );
};
