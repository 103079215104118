import { MOMENT_FORMAT } from '@app/helpers';
import ConfirmationModal from '@components/ConfirmDeleteModal';
import { useDisclosure } from '@hooks/useDisclosure';
import { Button } from '@lib/Button';
import { Table, TableActionMenu } from '@lib/Table';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { useGetSalesPaginated } from '@network/hooks';
import { Sales } from '@network/interface';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { CreateSalesModal } from '../modals';

export const SalesTable = () => {
  const {
    isOpen: isOpenAddSalesModal,
    onClose: onCloseAddSalesModal,
    onOpen: onOpenAddSalesModal,
  } = useDisclosure();

  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();

  const [selected, setSelected] = useState<Sales | null>(null);

  const handleDeleteClick = (id: Sales) => {
    setSelected(id);
    onDeleteOpen();
  };

  const confirmDelete = () => {
    // handleDelete(selected?._customer_id!);
  };

  const columns = useMemo<ColumnDef<Sales>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: '_sales_id',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Customer Name',
        accessorKey: 'customer_name',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Products Purchased',
        accessorFn: (row) => row,
        cell: (info) => JSON.parse(info.row.original?.purchases).length,
      },
      {
        header: 'Date Added',
        accessorKey: 'createdAt',
        cell: (info) => moment(info.getValue() as string).format(MOMENT_FORMAT),
      },
      {
        header: 'Last Updated',
        accessorKey: 'updatedAt',
        cell: (info) => moment(info.getValue() as string).format(MOMENT_FORMAT),
      },
      {
        header: 'Action',
        accessorFn: (row) => row,
        cell: (info) => {
          return (
            <TableActionMenu
              actions={['DELETE']}
              actionHandler={{
                handleDelete: handleDeleteClick,
              }}
              value={info.getValue() as string}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { sales, isLoading, paginationData, updatePage } =
    useGetSalesPaginated();

  return (
    <>
      {/* <div>
        <BaseSelect
          options={[10, 20, 30, 40, 50, 100].map((val) => ({
            key: val.toString(),
            value: val.toString(),
          }))}
        />
      </div> */}
      <div className='w-full bg-white flex items-center justify-end gap-5 my-5 rounded-md border border-slate-200 p-4 dark:border-slate-300'>
        <div className='w-[15%]'>
          <Button label='Add Sale' onClick={onOpenAddSalesModal} />
        </div>
      </div>
      <Table
        columns={columns}
        data={sales}
        totalPageCount={paginationData?.total_pages}
        updatePage={updatePage}
        isLoading={isLoading}
      />
      {/* <Table
        columns={columns}
        data={branches}
        totalPageCount={paginationData?.total_pages}
        updatePage={updatePage}
        // isLoading={isLoading}
      /> */}
      {/* view modal */}
      {/* create & edit modal */}
      <CreateSalesModal
        isOpen={isOpenAddSalesModal}
        onClose={onCloseAddSalesModal}
        prev={selected}
      />
      <ConfirmationModal
        icon={
          <div className='w-20 h-20 rounded-full bg-slate-200 flex items-center justify-center'>
            <DeleteForeverOutlined color='error' fontSize='large' />
          </div>
        }
        onSubmit={confirmDelete}
        loading={false}
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        title='Delete Customer'
        description={`Are you sure you want to delete this sale {${selected?._sales_id}}. This action cannot be undone once performed?`}
      />
      {/* confirm delete modal */}
    </>
  );
};
