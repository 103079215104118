import { Button } from '@lib/Button';
import { BaseInput } from '@lib/Input';
// import {  } from '@network/hooks';
import { Link } from 'react-router-dom';

export const UpdatePasswordPage = () => {
  return (
    <div className='w-4/5 max-h-[80%] bg-white rounded-3xl p-8 shadow-lg'>
      <h2 className='text-3xl font-bold text-primary-500 uppercase'>
        UPDATE PASSWORD
      </h2>
      <h4 className='text-sm text-gray-400'>
        Change Your Password From the Default One.
      </h4>
      <div className='mt-3'>
        <BaseInput
          label='New Password'
          name='password'
          placeholder='Enter your new password'
          type='password'
          //   onChange={handleChange}
          //   value={values.password}
          //   error={errors.password}
        />
        <BaseInput
          label='Confirm Password'
          name='confirm-password'
          placeholder='Confirm your  password'
          type='password'
          //   onChange={handleChange}
          //   value={values.password}
          //   error={errors.password}
        />
      </div>
      <Button
        label='UPDATE'
        // onClick={handleSubmit}
        // disabled={disabledButton}
        // isLoading={isLoading}
        loadingText='Updating...'
      />
      <div className='flex items-center gap-2 text-xs mt-5 justify-end'>
        <p className='text-gray-400'>Return to Login</p>
        <Link
          className='text-primary-500 hover:text-primary-600'
          to='/auth/login'>
          Login.
        </Link>
      </div>
    </div>
  );
};
