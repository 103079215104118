import React from 'react';
import { BranchTable } from './tables';
import { PageHeader } from '@components/PageHeader';

export const BranchManagementPage = () => {
  return (
    <div>
      <PageHeader title='Branch Management' />
      <BranchTable />
    </div>
  );
};
