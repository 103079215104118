import { Button } from '@lib/Button';
import { Modal, ModalFooter } from '@lib/Modal';

interface IProps {
  title: string;
  description: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  icon?: any;
  buttonColor?: string;
  label?: string;
  loading?: boolean;
  required?: boolean;
  proceedText?: string;
}

const ConfirmationModal = ({
  title,
  description,
  isOpen,
  onClose,
  onSubmit,
  icon,
  buttonColor,
  loading,
  required,
  label,
  proceedText,
}: IProps) => {
  return (
    <Modal title={title || ''} size='sm' isOpen={isOpen} onClose={onClose}>
      <section className=''>
        <div className='my-8 flex flex-col space-y-4 items-center p-4 py-2'>
          <div>{icon}</div>
          <div className='ml-4'>
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              className='text-gray-700 text-center'
            />
          </div>
        </div>

        <ModalFooter>
          <Button pale onClick={onClose} className='mr-2' label='Cancel' />
          <Button
            isLoading={loading}
            onClick={() => onSubmit?.()}
            label={proceedText || 'Yes, proceed'}
          />
        </ModalFooter>
      </section>
    </Modal>
  );
};

export default ConfirmationModal;
