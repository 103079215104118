import { useHttpPrivate } from '@network/api';
import {
  BaseError,
  Endpoints,
  IGetAllRolesResponse,
  IGetRolesPaginated,
  PaginationData,
  QueryKeys,
  Role,
} from '@network/interface';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

export const useGetRolesPaginated = () => {
  const httpPrivate = useHttpPrivate();
  const [paginator, setPaginator] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const [roles, setRoles] = useState<Role[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationData | null>(
    null
  );

  const { isLoading } = useQuery(
    [QueryKeys.GET_ROLES_PAGINATED],
    async () => {
      const { data } = await httpPrivate.get<IGetRolesPaginated>(
        `${Endpoints.GET_ROLES_PAGINATED}?page=${paginator.currentPage}&limit=${paginator.pageSize}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setRoles(data.data);
        setPaginationData(data?.pagination);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const updatePage = (page?: number, pageSize?: number) => {
    setPaginator((prev) => ({
      currentPage: page || prev.currentPage,
      pageSize: pageSize || prev.pageSize,
    }));
  };

  return { isLoading, roles, paginationData, paginator, updatePage };
};

export const useGetAllRoles = () => {
  const httpPrivate = useHttpPrivate();

  const [roles, setRoles] = useState<Role[]>([]);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_ROLES],
    async () => {
      const { data } = await httpPrivate.get<IGetAllRolesResponse>(
        `${Endpoints.GET_ALL_ROLES}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setRoles(data.data);
      },
      onError: (error: BaseError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  return { isLoading, roles };
};
