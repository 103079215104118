import { MOMENT_FORMAT } from '@app/helpers';
import { useDisclosure } from '@hooks/useDisclosure';
import { Button } from '@lib/Button';
import { Table, TableActionMenu } from '@lib/Table';
import { Branch } from '@network/interface';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import { useMemo, useState } from 'react';

export const ReportsTable = () => {
  const {
    isOpen: isOpenAddReportsModal,
    onClose: onCloseAddReportsModal,
    onOpen: onOpenAddReportsModal,
  } = useDisclosure();

  const [report_id, setReports_id] = useState<string | null>(null);

  const handleEditClick = (id: string) => {
    setReports_id(id);
    // onOpenAddReportsModal();
  };

  const handleViewClick = (id: string) => {
    setReports_id(id);
    // onOpenViewReportsModal();
  };

  const handleDeleteClick = (id: string) => {
    setReports_id(id);
    // onOpenConfirmDeleteModal();
  };

  const confirmDelete = (id: string) => {
    // call delete function from delete hook
  };

  const data = [
    {
      reportsId: 1001,
      report: 'New Sale',
      date: 'Jan 6, 2022 09:21',
      status: 'ACTIVE',
      selected: false,
    },
    {
      reportsId: 1002,
      report: 'New Expense',
      date: 'Jan 6, 2022 09:21',
      status: 'ACTIVE',
      selected: false,
    },
  ];

  const columns = useMemo<ColumnDef<Branch>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: 'reportsId',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Reports',
        accessorKey: 'report',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: (info) => (
          <span
            className={`px-3 py-1 text-xs rounded-md text-white ${
              info.getValue() === 'ACTIVE'
                ? 'bg-green-500'
                : info.getValue() === 'INACTIVE'
                ? 'bg-red-500'
                : ''
            }`}>
            {info.getValue() as string}
          </span>
        ),
      },
      {
        header: 'Date Added',
        accessorKey: 'createdAt',
        cell: (info) => moment(info.getValue() as string).format(MOMENT_FORMAT),
      },
      {
        header: 'Last Updated',
        accessorKey: 'updatedAt',
        cell: (info) => moment(info.getValue() as string).format(MOMENT_FORMAT),
      },
      {
        header: 'Action',
        accessorKey: 'branchID',
        cell: (info) => {
          return (
            <TableActionMenu
              actions={['VIEW', 'DELETE', 'EDIT']}
              actionHandler={{
                handleView: handleViewClick,
                handleEdit: handleEditClick,
              }}
              value={info.getValue() as string}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //   const { branches, isLoading, paginationData, updatePage } =
  //     useGetBranchPaginated();

  return (
    <>
      {/* <div>
        <BaseSelect
          options={[10, 20, 30, 40, 50, 100].map((val) => ({
            key: val.toString(),
            value: val.toString(),
          }))}
        />
      </div> */}
      <div className='w-full bg-white flex items-center justify-end gap-5 my-5 rounded-md border border-slate-200 p-4 dark:border-slate-300'>
        <div className='w-[15%]'>
          <Button
            label='Add Report'
            //   onClick={onOpenAddReportModal}
          />
        </div>
      </div>
      <Table
        columns={columns}
        data={data}
        totalPageCount={5}
        // updatePage={updatePage}
        // isLoading={isLoading}
      />
      {/* <Table
        columns={columns}
        data={branches}
        totalPageCount={paginationData?.total_pages}
        updatePage={updatePage}
        // isLoading={isLoading}
      /> */}
      {/* view modal */}
      {/* create & edit modal */}
      {/* <AddReportModal
        isOpen={isOpenAddReportModal}
        onClose={onCloseAddReportModal}
        prev={branch_id}
      /> */}
      {/* confirm delete modal */}
    </>
  );
};
