import { Button } from '@lib/Button';
import { BaseInput } from '@lib/Input';
import { Modal, ModalFooter } from '@lib/Modal';
import { BaseSelect } from '@lib/Select';
import { useCreateBranch } from '@network/hooks';
import { Branch } from '@network/interface';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  prev?: Branch | null;
}

export const AddBranchModal = ({ isOpen, onClose, prev }: IProps) => {
  const {
    isLoading,
    isEdit,
    handleSubmit,
    errors,
    values,
    handleChange,
    isDisabled,
    countryOptions,
    stateOptions,
    headBranchOptions,
    statusOptions,
    citiesOptions,
  } = useCreateBranch({ prev, handleCloseAddBranchModal: onClose });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isEdit ? `Editing Branch: ${prev?.name}` : 'Add Branch'}>
      <div className='p-4'>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Branch Name'
            placeholder='Enter Branch Name'
            name='name'
            type='name'
            onChange={handleChange}
            value={values.name}
            error={errors.name}
          />
          <BaseInput
            label='Branch Email'
            placeholder='Enter Email'
            name='email'
            type='email'
            onChange={handleChange}
            value={values.email}
            error={errors.email}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseInput
            label='Branch Phone Number'
            placeholder='Enter Phone Number'
            name='phone'
            type='phone'
            onChange={handleChange}
            value={values.phone}
            error={errors.phone}
          />
          <BaseSelect
            label='Head Branch'
            placeholder='Select Branch Status'
            options={headBranchOptions}
            name='isHeadBranch'
            type='isHeadBranch'
            onChange={handleChange}
            value={values.isHeadBranch?.toString()}
            error={errors.isHeadBranch}
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseSelect
            label='Branch Country'
            placeholder='Select Branch Country'
            options={countryOptions}
            name='country'
            type='country'
            onChange={handleChange}
            value={values.country}
            error={errors.country}
            disabled={!countryOptions || countryOptions.length < 0}
          />
          <BaseSelect
            label='Branch State'
            placeholder='Select Branch State'
            options={stateOptions}
            name='state'
            type='state'
            onChange={handleChange}
            value={values.state}
            error={errors.state}
            disabled={
              !values.country || !stateOptions || stateOptions.length < 0
            }
          />
          <BaseSelect
            label='Branch City'
            placeholder='Select Branch City'
            options={citiesOptions}
            name='city'
            type='city'
            onChange={handleChange}
            value={values.city}
            error={errors.city}
            disabled={
              !values.state || !citiesOptions || citiesOptions.length < 0
            }
          />
        </div>
        <div className='flex justify-between gap-4'>
          <BaseSelect
            label='Branch Status'
            placeholder='Select Branch Status'
            options={statusOptions}
            name='status'
            type='status'
            onChange={handleChange}
            value={values.status}
            error={errors.status}
          />
        </div>
        <BaseInput
          label='Branch Address'
          placeholder='Enter Branch Address'
          name='address'
          type='address'
          onChange={handleChange}
          value={values.address}
          error={errors.address}
        />
        {values.isHeadBranch?.toString() === 'true' ? (
          <div className='mb-4'>
            <span className='text-sm text-gray-400'>
              <span className='font-bold'>NOTE:</span>
              Making this branch head branch will remove the previous head
              branch, if any!.
            </span>
          </div>
        ) : null}
      </div>
      <ModalFooter>
        <Button
          label={isEdit ? 'Save Branch' : 'Create Branch'}
          isLoading={isLoading}
          disabled={isDisabled}
          onClick={handleSubmit}
          type='submit'
        />
      </ModalFooter>
    </Modal>
  );
};
